const risks = {
  "unknown": {
    weight: 0,
    startColor: '#CCCCCC',
    stopColor: '#DDDDDD'
  },
  "synergy": {
    weight: 2,
    startColor: '#81b1f8',
    stopColor: '#578ee1'
  },
  "low": {
    weight: 2,
    startColor: '#81b1f8',
    stopColor: '#578ee1'
  },
  "decrease": {
    weight: 3,
    startColor: '#81b1f8',
    stopColor: '#578ee1'
  },
  "caution": {
    weight: 6,
    startColor: '#f2bf76',
    stopColor: '#c63417'
  },
  "unsafe": {
    weight: 8,
    startColor: '#f2bf76',
    stopColor: '#c63417'
  },
  "dangerous": {
    weight: 10,
    startColor: '#e6642c',
    stopColor: '#c63417'
  }
}

const drugs = [
  {
    key: 'alcohol', // Alcohol
    type: 'anaesthetic',
    tripsit: 'https://drugs.tripsit.me/alcohol',
  },
  {
    key: 'ghb', // GHB/GBL
    type: 'depressant',
    tags: ['depressant','habit-forming', 'common', 'sedative'],
    tripsit: 'https://drugs.tripsit.me/GHB',
  },
  {
    key: 'cocaine', // Cocaïne
    type: '',
    tripsit: 'https://drugs.tripsit.me/cocaine',
  },
  {
    key: 'amphetamine', // Speed
    type: 'amphetamine',
    tripsit: 'https://drugs.tripsit.me/amphetamine',
  },
  {
    key: 'methamphetamine', // crystal meth
    type: 'amphetamine',
    tripsit: 'https://drugs.tripsit.me/methamphetamine',
  },
  {
    key: 'fourmmc', // 4mmc
    type: 'amphetamine',
    tripsit: 'https://drugs.tripsit.me/fourmmc',
  },
  {
    key: 'threemmc', // 3mmc
    type: 'amphetamine',
    tags: ['stimulant', 'habit-forming', 'empathogen'],
    tripsit: 'https://drugs.tripsit.me/threemmc',
  },/* 
  {
    key: '3-mec', // 3mmc/3mec
    type: 'amphetamine',
    tags: ['stimulant', 'habit-forming', 'empathogen'],
    tripsit: 'https://drugs.tripsit.me/3-mec',
  }, */
  {
    key: 'mdma', // MDMA/XTC
    type: '',
    tripsit: 'https://drugs.tripsit.me/mdma',
  },
  {
    key: 'ketamine', // Ketamine
    type: 'anaesthetic',
    tripsit: 'https://drugs.tripsit.me/ketamine',
  }/* ,
  {
    key: 'benzodiazepines', // Ketamine
    type: 'benzodiazepines',
    tripsit: 'https://wiki.tripsit.me/wiki/Benzodiazepines',
  } */
];



export default {
  drugs() {
      return drugs;
  },
  drug(key) {
      return this.drugs.find(x => x.key = key);
  },
  risks() {
      return risks;
  },
  risk(key) {
      return risks[key];
  },
  
  combo(key1, key2) {
      const combos = this.combos();
      if(combos[`${key1}___${key2}`] !== undefined) return combos[`${key1}___${key2}`];
      if(combos[`${key2}___${key1}`] !== undefined) return combos[`${key2}___${key1}`];
      return null;
  },
  
  combos() {

    const alcohol___amphetamine = {
        risk: 'caution',
        note: 'drinking_on_stimulants'
    }
    const any___4mmc = {
        risk: 'caution',
        note: 'fourmmc_unknown'
    }
    const any___3mmc = {
        risk: 'caution',
        note: 'threemmc_unknown'
    }

    return {
        // ALCOHOL

        "alcohol___ghb": {
            risk: 'dangerous',
            note: 'drinking_on_ghb',
        },
        'alcohol___cocaine': {
            risk: 'unsafe',
            note: 'drinking_on_cocaine',
        },
        'alcohol___amphetamine': alcohol___amphetamine,
        'alcohol___methamphetamine': alcohol___amphetamine,
        
        'fourmmc___alcohol': any___4mmc,
        'threemmc___alcohol': any___3mmc,
        
        'alcohol___mdma': {
            risk: 'caution',
            note: 'drinking_on_mdma',
        },
        'alcohol___ketamine': {
            risk: 'dangerous',
            note: 'drinking_on_ketamine',
        },

        // ghb

        'cocaine___ghb': {
          risk: 'caution',
          note: 'ghb_on_cocaine',
        },
        'ghb___amphetamine': {
          risk: 'caution',
          note: 'ghb_on_stimulants',
        },
        'ghb___methamphetamine': {
          risk: 'caution',
          note: 'ghb_on_stimulants',
        },
        
        'fourmmc___ghb': any___4mmc,
        'threemmc___ghb': any___3mmc,

        'ghb___mdma': {
          risk: 'caution',
          note: 'ghb_on_mdma',
        },
        'ghb___ketamine': {
          risk: 'dangerous',
          note: 'ghb_on_ketamine',
        },

        // cocaine

        "amphetamine___cocaine": {
          risk: 'caution',
          note: 'cocaine_on_amphetamine',
        },
        "cocaine___methamphetamine": {
          risk: 'caution',
          note: 'cocaine_on_methamphetamine',
        },
        'fourmmc___cocaine': any___4mmc,
        'threemmc___cocaine': any___3mmc,

        
        'cocaine___mdma': {
          risk: 'caution',
          note: 'cocaine_on_mdma',
        },
        'cocaine___ketamine': {
          risk: 'caution',
          note: 'amphetamine_on_ketamine',
        },

        // amphetamine

        'amphetamine___methamphetamine': {
          risk: 'caution',
          note: 'amphetamine_on_meth',
        },
        'fourmmc___amphetamine': any___4mmc,
        'threemmc___amphetamine': any___3mmc,

        'amphetamine___mdma': {
          risk: 'low',
          note: 'amphetamine_on_mdma',
        },
        'amphetamine___ketamine': {
          risk: 'caution',
          note: 'amphetamine_on_ketamine',
        },

        // methamphetamine

        'fourmmc___methamphetamine': any___4mmc,
        'threemmc___methamphetamine': any___3mmc,

        'methamphetamine___mdma': {
          risk: 'low',
          note: 'amphetamine_on_mdma',
        },
        'methamphetamine___ketamine': {
          risk: 'caution',
          note: 'amphetamine_on_ketamine',
        },

        // fourmmc
        'fourmmc___mdma': {
          risk: 'low',
          note: 'amphetamine_on_mdma',
        },
        'fourmmc___ketamine': {
          risk: 'caution',
          note: 'amphetamine_on_ketamine',
        },



        // threemmc
        'threemmc___mdma': {
          risk: 'low',
          note: 'amphetamine_on_mdma',
        },
        'threemmc___ketamine': {
          risk: 'caution',
          note: 'amphetamine_on_ketamine',
        },


        // 4/threemmc

        'threemmc___fourmmc': {
          risk: 'caution',
          note: 'threeandfourmmc_unknown'
        },
        'fourmmc___threemmc': {
          risk: 'caution',
          note: 'threeandfourmmc_unknown'
        },
        
        // mdma
        'mdma___ketamine': {
          risk: 'low',
          note: 'amphetamine_on_ketamine',
        },

        


        'ketamine___benzodiazepines': {
            risk: 'caution',
            note: 'ketamine_on_benzodiazepines',
        },

        'mdma___benzodiazepines': {
            risk: 'low',
            note: 'benzodiazepines_on_stimulants',
        },

        'fourmmc___benzodiazepines': {
            risk: 'caution',
            note: 'threeandfourmmc_benzodiazepines',
        },

        'threemmc___benzodiazepines': {
            risk: 'caution',
            note: 'threeandfourmmc_benzodiazepines',
        },

        'methamphetamine___benzodiazepines': {
            risk: 'low',
            note: 'benzodiazepines_on_stimulants',
        },

        'amphetamine___benzodiazepines': {
            risk: 'low',
            note: 'benzodiazepines_on_stimulants',
        },

        'cocaine___benzodiazepines': {
            risk: 'low',
            note: 'benzodiazepines_on_stimulants',
        },

        'ghb___benzodiazepines': {
            risk: 'dangerous',
            note: 'ghb_on_benzodiazepines',
        },

        'alcohol___benzodiazepines': {
            risk: 'dangerous',
            note: 'drinking_on_benzodiazepines',
        },


        /* '___': {
            risk: '',
            note: '',
        }, */
    }

  }
};