<template>
  <modal :show="dontForgetCheckoutModalOpen" @close="acceptModal">
    <div class="p-6">
      <h5 class="font-semibold mb-12">{{ $t("modals.checkout.body") }}</h5>

      <t-button variant="primary" @click="acceptModal">
        {{ $t("action.ok") }}
      </t-button>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import { mapGetters } from "vuex";

export default {
  name: "Checkout",

  components: {
    Modal,
  },

  methods: {
    acceptModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: "dontForgetCheckoutModalOpen",
        modalState: false,
      });

      this.$cookies.set("hasAcceptedDontForgetCheckout", true, "1h");
    },
  },

  computed: {
    ...mapGetters(["dontForgetCheckoutModalOpen"]),
  },
};
</script>

<style scoped></style>
