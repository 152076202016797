<template>
  <div class="fixed inset-x-0 bottom-0 pb-2 z-999 sm:pb-5">
    <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg shadow-lg bg-brand-lightblue sm:p-3">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex items-center flex-1 w-0">
            <span class="flex p-2 bg-white rounded-lg">
              <svg
                class="w-6 h-6 text-brand-dark"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                ></path>
              </svg>
            </span>
            <p class="ml-3 font-medium truncate text-brand-dark">
              {{ $t("app.update-available") }}
            </p>
          </div>
          <div class="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
            <button
              type="button"
              class="flex p-3 text-sm font-medium text-white uppercase rounded-md bg-brand-dark focus:outline-none focus:ring-2 focus:ring-white"
              @click="accept"
            >
              {{ $t("app.update-action") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "update-pwa-notice",
  methods: {
    accept() {
      this.$emit("update-accepted");
    },
  },
};
</script>
