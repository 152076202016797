<template>
  <div
    class="w-2/3 mb-2 bg-white flex shadow-lg py-2 px-4 border-l-2 items-center"
    :class="className"
  >
    <div class="flex-1">
      <p class="font-bold text-brand-dark">
        {{ $t(`${translationKey}${title}`) }}
      </p>
      <p class="text-sm text-gray-500">{{ alert.text }}</p>
    </div>

    <icon-close class="w-4 h-4 text-gray-300" @click="onCloseClick" />
  </div>
</template>

<script>
import IconClose from "assets/icons/close.svg";

export default {
  name: "Alert",

  props: {
    alert: {
      type: Object,
      required: true
    }
  },

  components: {
    IconClose
  },

  data() {
    return {
      translationKey: "alerts."
    };
  },

  methods: {
    onCloseClick() {
      this.$store.commit("alerts/REMOVE_ALERT", this.alert);
    }
  },

  computed: {
    title() {
      switch (this.alert.type) {
        case "ERROR":
          return "error";
        case "WARNING":
          return "warning";
        case "INFO":
          return "info";
        default:
          return "success";
      }
    },

    className() {
      switch (this.alert.type) {
        case "ERROR":
          return "border-red-500";
        case "WARNING":
          return "border-yellow-500";
        case "INFO":
          return "border-blue-500";
        default:
          return "border-green-400";
      }
    }
  }
};
</script>

<style scoped></style>
