import api from "utils/api";
const resource = "/api/quizzes";

export default {
  async all() {
    try {
      const { data } = await api.get(`${resource}`);
      return data;
    } catch (e) {
      return e.message;
    }
  },

  async detail(uuid) {
    try {
      const { data } = await api.get(`${resource}/${uuid}`);

      return data;
    } catch (e) {
      return e.message;
    }
  },

  async finishQuiz(uuid, { quizIsFilledIn, rightAnswers }) {
    try {
      const { data } = await api.put(`${resource}/${uuid}/results`, {
        quizIsFilledIn,
        rightAnswers
      });
      return data;
    } catch (e) {
      return e.message;
    }
  }
};
