var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-col items-center justify-between flex-1 h-full max-h-screen py-8 overflow-x-hidden overflow-y-auto"},[_c('ul',{staticClass:"flex-1 flex-grow w-full mb-10 desktop-nav"},[_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('logo-small',{staticClass:"w-12 h-12",class:{
          'bg-brand-primary fill-white p-2 rounded-lg': _vm.isCheckedIn
        }})],1)],1),_c('li',[_c('router-link',{class:{
          'router-link-active': _vm.subIsActive([
            '/journal',
            '/extensive-journal',
            '/assessment'
          ]),
        },attrs:{"to":{ name: 'home' },"exact":""}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-home')],1)])],1),(_vm.isAuthenticated && _vm.isProfileLoaded)?[_c('li',[_c('router-link',{attrs:{"to":{ name: 'statistics-index' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-stats')],1)])],1)]:_vm._e(),(_vm.isAuthenticated && _vm.isProfileLoaded)?[_c('li',{staticClass:"flex flex-col space-y-8",class:{'space-y-4': _vm.isCheckedIn}},[_c('router-link',{attrs:{"to":{ name: 'my-events' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-event-map-pin')],1)]),(!_vm.currentEvent)?_c('button',{staticClass:"flex items-center justify-center w-16 h-16 p-1 text-white rounded-full bg-brand-navblue",attrs:{"type":"button"},on:{"click":_vm.openCreateEventModal}},[_c('div',{staticClass:"w-5 h-5"},[_c('icon-plus',{staticClass:"fill-current"})],1)]):_c('router-link',{staticClass:"flex items-center justify-center w-16 h-16 p-1 text-white border-4 rounded-full border-brand-dark bg-brand-navblue",attrs:{"to":{
            name: 'my-event',
            params: { uuid: _vm.currentEvent },
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('button',{staticClass:"w-8 h-8 text-xs font-bold text-white truncate",attrs:{"type":"button"},on:{"click":navigate}},[_c('span',{domProps:{"textContent":_vm._s(_vm.time)}})])]}}],null,false,4265557613)})],1)]:_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{ name: 'info-index' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-info')],1)])],1)],2),_c('div',{staticClass:"flex-1 flex-grow pb-10 mt-auto"}),_c('ul',{staticClass:"self-end w-full mt-auto justify-self-end desktop-nav"},[(_vm.isAuthenticated && _vm.isProfileLoaded)?[_c('li',[_c('router-link',{attrs:{"to":"/profile"}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-settings')],1)])],1)]:[_c('li',[_c('router-link',{staticClass:"navbar-user-icon",class:{
            'router-link-active': _vm.subIsActive([
              '/auth/login',
              '/auth/forgot',
              '/auth/register'
            ]),
          },attrs:{"exact":"","to":{
            name: 'login'
          }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-user',{staticClass:"router-link-active-hide"}),_c('icon-user-fill',{staticClass:"router-link-active-show"})],1)])],1),_c('li',{staticClass:"p-3"},[_c('locale-switcher',{staticClass:"w-full",attrs:{"short":""}})],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }