import RepositoryFactory from "repositories/RepositoryFactory";

const assessmentRepository = RepositoryFactory.get("assessment");

export default {
  state: {
    questions: [],
    results: {},
    completed_quiz: false
  },

  mutations: {
    SET_ASSESSMENT_QUESTIONS(state, payload) {
      state.questions = payload;
    },
    SET_ASSESSMENT_RESULTS(state, payload) {
      state.results = {
        ...state.results,
        ...payload
      };
    },
    SET_COMPLETED_QUIZ(state, payload) {
      state.completed_quiz = payload;
    }
  },

  actions: {
    async getAllQuestions({ commit }) {
      const response = await assessmentRepository.all();
      commit("SET_ASSESSMENT_QUESTIONS", response.data);
    },

    async canParticipateInAssessmentQuiz({ commit }) {
      const response = await assessmentRepository.userCanParticipate();
      commit("SET_COMPLETED_QUIZ", !response.data);
    },

    async submitAssessmentQuiz({ state, commit }) {
      await assessmentRepository.create({
        result: state.results,
        completed_quiz: true
      });

      commit("SET_COMPLETED_QUIZ", true);
    }
  },

  getters: {
    questions: state => state.questions,
    questionsLength: state => state.questions.length,
    results: state => state.results,
    completed_quiz: state => state.completed_quiz
  }
};
