<template>
  <div class="flex-col items-center justify-between flex-1 h-full max-h-screen py-8 overflow-x-hidden overflow-y-auto">
    <ul class="flex-1 flex-grow w-full mb-10 desktop-nav">
      <li class="">
        <router-link :to="{ name: 'home' }">
          <logo-small class="w-12 h-12" :class="{
            'bg-brand-primary fill-white p-2 rounded-lg': isCheckedIn
          }" />
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'home' }"
          exact
          :class="{
            'router-link-active': subIsActive([
              '/journal',
              '/extensive-journal',
              '/assessment'
            ]),
          }"
        >
          <div class="navbar-item-icon">
            <icon-home />
          </div>
        </router-link>
      </li>

      <template v-if="isAuthenticated && isProfileLoaded">
        <li>
          <router-link :to="{ name: 'statistics-index' }">
            <div class="navbar-item-icon">
              <icon-stats />
            </div>
          </router-link>
        </li>
      </template>
      
      <template v-if="isAuthenticated && isProfileLoaded">

        <li class="flex flex-col space-y-8" :class="{'space-y-4': isCheckedIn}">
          <router-link :to="{ name: 'my-events' }">
            <div class="navbar-item-icon">
              <icon-event-map-pin />
            </div>
          </router-link>
        
          <button
            type="button"
            class="flex items-center justify-center w-16 h-16 p-1 text-white rounded-full bg-brand-navblue"
            @click="openCreateEventModal"
            v-if="!currentEvent"
          >
            <div class="w-5 h-5">
              <icon-plus class="fill-current" />
            </div>
          </button>
          <router-link
            v-else
            class="flex items-center justify-center w-16 h-16 p-1 text-white border-4 rounded-full border-brand-dark bg-brand-navblue"
            :to="{
              name: 'my-event',
              params: { uuid: currentEvent },
            }"
            v-slot="{ navigate }"
          >
            <button
              type="button"
              @click="navigate"
              class="w-8 h-8 text-xs font-bold text-white truncate"
            >
              <span v-text="time"></span>
            </button>
          </router-link>
        </li>
      </template>
      

      <li>
        <router-link :to="{ name: 'info-index' }">
          <div class="navbar-item-icon">
            <icon-info />
          </div>
        </router-link>
      </li>
      
    </ul>
    <div class="flex-1 flex-grow pb-10 mt-auto"></div>
    <ul class="self-end w-full mt-auto justify-self-end desktop-nav">

      <template v-if="isAuthenticated && isProfileLoaded">
        <li>
          <router-link to="/profile">
            <div class="navbar-item-icon">
              <icon-settings />
            </div>
          </router-link>
        </li>
      </template>
      <template v-else>
        <li>
          <router-link exact :to="{
              name: 'login'
            }"
            class="navbar-user-icon"
            :class="{
              'router-link-active': subIsActive([
                '/auth/login',
                '/auth/forgot',
                '/auth/register'
              ]),
            }"
          >
            <div class="navbar-item-icon">
              <icon-user class="router-link-active-hide" />
              <icon-user-fill class="router-link-active-show" />
            </div>
          </router-link>
        </li>
        <li class="p-3">
          <locale-switcher short class="w-full"></locale-switcher>
        </li>
      </template>
      
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyEventRepository from "../../repositories/MyEventRepository";
import { modalTypes } from "../../utils/constants";
import IconEventMapPin from "@/assets/icons/event-map-pin.svg";
import IconHome from "@/assets/icons/home.svg";
import IconInfo from "@/assets/icons/info.svg";
import IconStats from "@/assets/icons/stats.svg";
import IconPlus from "@/assets/icons/plus.svg";
import IconUser from "@/assets/icons/user-circle.svg";
import IconUserFill from "@/assets/icons/user-circle-fill.svg";
import IconSettings from "@/assets/icons/settings_desktop.svg";
import LogoSmall from "@/assets/logo/budd-small.svg";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "DesktopNav",

  components: {
    IconHome,
    IconStats,
    IconInfo,
    IconEventMapPin,
    IconPlus,
    IconSettings,
    LogoSmall,
    IconUser,
    IconUserFill,
    LocaleSwitcher
  },

  props: {
    darkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      interval: null,
      time: "",
    };
  },

  mounted() {
    if (this.currentEvent) {
      this.startTimer();
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },

  watch: {
    currentEvent(newValue) {
      if (newValue && !this.time) {
        this.startTimer();
      }
    },
  },

  methods: {
    subIsActive(subRoute) {
      const paths = Array.isArray(subRoute) ? subRoute : [subRoute];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },

    secondsToTime(seconds) {
      const h = Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0"),
        m = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0");

      return `${h}:${m}`;
    },

    async startTimer() {
      const { data } = await MyEventRepository.get(this.currentEvent);
      const checkins = data.event.log
        .filter((log) => log.type === "checkin")
        .map((log) => new Date(log.created_at).getTime());
      const latestCheckin = Math.max(...checkins);

      this.interval = setInterval(() => {
        const date = Date.now();

        this.time = this.secondsToTime((date - latestCheckin) / 1000);
      }, 1000);
    },

    openCreateEventModal() {
      if (this.currentEvent) {
        return;
      }

      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.CREATE_EVENT_MODAL,
        modalState: true,
      });
    },
  },

  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "isCheckedIn",
      "currentEvent",
    ]),
  },
};
</script>

<style scoped>
.navbar-user-icon .router-link-active-hide {
  display:none;
}
.navbar-user-icon .router-link-active-show {
  display:flex;
}
.navbar-user-icon.router-link-active .router-link-active-show {
  display:none;
}
.navbar-user-icon.router-link-active .router-link-active-hide {
  display:flex;
}
</style>
