<template>
  <modal :show="dailyMoodModalOpen" @close="closeModal">
    <div class="p-6">
      <h5 class="text-center font-semibold">
        {{ $t("modals.daily_mood.title") }}
      </h5>

      <div class="my-8">
        <mood-selector @update="onMoodChange" />

        <t-textarea
          v-model="content"
          :placeholder="$t('modals.daily_mood.placeholder')"
        />
      </div>

      <t-button variant="primary" @click="createMood">{{
        $t("action.save")
      }}</t-button>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import { mapGetters } from "vuex";
import MoodSelector from "../MoodSelector";
import repositoryFactory from "@/repositories/RepositoryFactory";
import { createAlert } from "@/utils/constants";

const journalRepository = repositoryFactory.get("journal");

export default {
  name: "DailyMood",

  components: {
    MoodSelector,
    Modal,
  },

  data() {
    return {
      mood: "neutral",
      content: "",
    };
  },

  methods: {
    closeModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: "dailyMoodModalOpen",
        modalState: false,
      });
    },

    onMoodChange($event) {
      this.mood = $event;
    },

    async createMood() {
      const response = await journalRepository.createNote({
        mood: this.mood,
        content: this.content,
      });

      if (!response.success) {
        this.$store.commit(
          "alerts/ADD_ALERT",
          createAlert(response.data, "ERROR")
        );
      } else {
        this.$store.commit(
          "alerts/ADD_ALERT",
          createAlert(response.data, "SUCCESS")
        );
      }

      this.closeModal();
    },
  },

  computed: {
    ...mapGetters(["dailyMoodModalOpen"]),
  },
};
</script>

<style scoped></style>
