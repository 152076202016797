<template>
  <modal :show="deleteNoteModalOpen" @close="handleCancel">
    <div class="p-6">
      <h5 class="font-semibold mb-12">
        {{ $t("modals.delete_note.body") }}
      </h5>

      <t-button variant="danger" @click="acceptModal">{{
        $t("modals.delete_note.accept")
      }}</t-button>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import { mapGetters } from "vuex";

export default {
  name: "DeleteNote",
  components: {
    Modal
  },

  methods: {
    handleCancel() {
      this.$store.commit("TOGGLE_DELETE_NOTE_MODAL", false);
      this.$store.commit("journal/SET_NOTE_TO_DELETE", null);
    },

    acceptModal() {
      this.$store.commit("TOGGLE_DELETE_NOTE_MODAL", false);
      this.$store.dispatch("journal/deleteNote", this.noteToDelete.id);
    }
  },

  computed: {
    ...mapGetters({
      deleteNoteModalOpen: "deleteNoteModalOpen",
      noteToDelete: "journal/noteToDelete"
    })
  }
};
</script>

<style scoped></style>
