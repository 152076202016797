<template>
  <div
    class="sticky top-0 z-50 flex items-center justify-center h-24 p-2 bg-transparent md:hidden"
    :class="{
      dark: !!darkMode,
      'bg-gradient-to-b from-brand-dark via-brand-dark to-brand-dark-transparent':
        !!darkMode,
      'bg-gradient-to-b from-brand-lightblue via-brand-lightblue to-brand-lightblue-transparent':
        !darkMode,
    }"
  >
    <portal-target
      name="topbar-left"
      class="relative flex items-center justify-center flex-shrink-0 w-16 h-16 p-0"
      :class="{ 'text-white': !!darkMode }"
    />

    <div class="flex items-center justify-center flex-1">
      <logo
        class="w-auto mx-auto select-none"
        :type="!!darkMode ? 'white' : 'dark'"
      />
    </div>

    <portal-target
      name="topbar-right"
      class="relative flex items-center justify-center flex-shrink-0 w-16 h-16 p-0"
      :class="{ 'text-white': !!darkMode }"
    />
  </div>
</template>

<script>
import ApplicationLogo from "@/components/ApplicationLogo.vue";

export default {
  name: "TopBar",
  components: {
    logo: ApplicationLogo,
  },
  props: {
    darkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
