import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "actions/auth";
import { AUTH_TOKEN_IDENTIFIER } from "cache/auth";
import { USERID_COOKIE_IDENTIFIER } from "cache/user";
import { USER_REQUEST } from "actions/user";
import { CSRF_REQUEST } from "actions/csrf";
import api from "utils/api";
import cookie from 'vue-cookies'

const state = {
  token: localStorage.getItem(AUTH_TOKEN_IDENTIFIER) || null,
  status: ""
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = "success";
    state.token = token;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.status = "";
    state.token = null;
  }
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      dispatch(CSRF_REQUEST);
      commit(AUTH_REQUEST);

      api
        .post("/api/token", credentials)
        .then(resp => {
          const token = resp.data.token;

          localStorage.setItem(AUTH_TOKEN_IDENTIFIER, token); // store the token in localstorage

          commit(AUTH_SUCCESS, token);

          dispatch(USER_REQUEST); // you have your token, now log in your user :)
          resolve(resp);
        })
        .catch(error => {
          localStorage.removeItem(AUTH_TOKEN_IDENTIFIER);

          commit(AUTH_ERROR, error);

          reject(error);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    // return new Promise(resolve => {
      // send request to remove current token to backend
      return api
        .post("/api/token/out")
        .then(() => {
          commit(AUTH_LOGOUT);
        })
        .catch(() => {
        })
        .then(() => {
          localStorage.removeItem(AUTH_TOKEN_IDENTIFIER);
          if (cookie.isKey(USERID_COOKIE_IDENTIFIER)) {
            cookie.remove(USERID_COOKIE_IDENTIFIER);
          }
          commit(AUTH_LOGOUT)
        });
    // });
  }
};

const getters = {
  isAuthenticated: state => !!state.token,
  apiToken: state => state.token,
  authStatus: state => state.status
};

const authModule = {
  state,
  mutations,
  actions,
  getters
};

export default authModule;
