import api from "utils/api";
const resource = "/api/my/todos";

export default {
  all() {
    return api.get(`${resource}`);
  },
  get(uuid) {
    return api.get(`${resource}/${uuid}`);
  },
  create(data) {
    return api.post(`${resource}`, data);
  },
  update(uuid, data) {
    return api.put(`${resource}/${uuid}`, data);
  },
  delete(uuid) {
    return api.delete(`${resource}/${uuid}`);
  },
  syncExampleTodosToChecklist(role) {
    return api.post(`${resource}/sync-in-example-todos/${role}`);
  }
};
