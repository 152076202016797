export const getUniqueValues = (valuesArray, uniqueValue) =>
  Array.from(new Set(valuesArray.map((value) => value[uniqueValue])));

// Add minutes to a date
export const addMinutesToDate = (minutes) =>
  new Date(Date.now() + minutes * 60000);

// Check if date is in the past in ms
export const isDateInPast = (date) => {
  if (date instanceof Date) {
    const now = new Date();
    return date.getTime() < now.getTime();
  }

  return true;
};
