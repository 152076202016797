import Vue from "vue";

import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Meta from "vue-meta";
import VueCookies from "vue-cookies";
import PortalVue from "portal-vue";
import VueOffline from "vue-offline";

import VueTailwind from "vue-tailwind/dist/full";
import VueTailwindSettings from "./settings/vue-tailwind";
  
import BrowserDetect from "vue-browser-detect-plugin";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import VueApexCharts from "vue-apexcharts";

import store from "./store";
import router from "./router";
import i18n from "./i18n";
import wb from "./registerServiceWorker";
import App from "./App.vue";

import DefaultLayout from "@/layouts/Default";
import AuthLayout from "@/layouts/Auth";
import DynamicLayout from "@/layouts/DynamicLayout";

import Wrapper from "./components/Shared/Wrapper";
import PortalBack from "./components/Shared/PortalBack";
import Avatar from "./components/Shared/Avatar";

import Loading from "./components/Shared/Loading";
import IconLoading from "@/assets/icons/loading.svg";

import VueCountdown from '@chenfengyuan/vue-countdown';

import "./assets/styles/app.css";

Vue.use(VueApexCharts);
Vue.use(VueAwesomeSwiper);
Vue.use(VueCookies);
Vue.use(VueMoment, { moment });
Vue.use(PortalVue);
Vue.use(Meta);
Vue.use(VueTailwind, VueTailwindSettings);
Vue.use(BrowserDetect);
Vue.use(VueOffline, {
  mixin: true,
  storage: false,
});

Vue.prototype.$workbox = wb;
Vue.prototype.$isProductionEnv = process.env.NODE_ENV === "production";
Vue.config.productionTip = false;

Vue.component("default-layout", DefaultLayout);
Vue.component("wrapper", Wrapper);
Vue.component("PortalBack", PortalBack);
Vue.component("auth-layout", AuthLayout);
Vue.component("application-default-layout", DynamicLayout);
Vue.component("avatar", Avatar);
Vue.component("icon-loading", IconLoading);
Vue.component("loading", Loading);
Vue.component("apex-chart", VueApexCharts);
Vue.component(VueCountdown.name, VueCountdown);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
