import api from 'utils/api';
const resource = '/api/tips';

export default {
    all(params) {
        return api.get(`${resource}`, { params });
    },
    get(uuid) {
        return api.get(`${resource}/${uuid}`);
    },
    proposed() {
        return api.get(`${resource}/proposed`);
    },
    random() {
        return api.get(`${resource}/random`);
    },
};