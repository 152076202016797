import Vue from "vue";
import Router from "vue-router";
import VueGtm from "@gtm-support/vue2-gtm";
import { ifAuthenticated } from "./routeGuards";
import { profileRoutes } from "./profile";
import { eventsRoutes } from "./events";
import { infoRoutes } from "./info";
import { authRoutes } from "./auth";
import { assessmentRoutes } from "./assessment";
import { journalRoutes } from "./journal";
import { statisticsRoutes } from "./statistics";
import { tutorialRoutes } from "./tutorial";

Vue.use(Router);
const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "application-default" },
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
    // beforeEnter: ifAuthenticated,
  },
  {
    path: "/policy",
    name: "policy",
    meta: { layout: "default" },
    component: () =>
      import(/* webpackChunkName: "policy" */ "@/views/Policy.vue"),
  },
  {
    path: "/tips",
    name: "my-tips",
    meta: { layout: "application-default" },
    props: true,
    component: () =>
      import(/* webpackChunkName: "event-overview" */ "@/views/tips/Index.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "*",
    name: "404",
    redirect: { name: "home" },
  },
  ...eventsRoutes,
  ...infoRoutes,
  ...statisticsRoutes,
  ...tutorialRoutes,
  ...profileRoutes,
  ...journalRoutes,
  ...assessmentRoutes,
  ...authRoutes,
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

if (
  process.env.VUE_APP_GTM_ID !== undefined &&
  process.env.VUE_APP_GTM_ID.length
) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    debug: process.env.NODE_ENV !== "production",
    enabled: process.env.NODE_ENV === "production",
    vueRouter: router,
    compatibility: true,
  });
}

export default router;
