<template>
  <modal :show="createEventModalOpen" @close="handleClose">
    <div class="p-6">
      <h5 class="mb-12 font-semibold">
        {{ $t("modals.create_event.body") }}
      </h5>

      <div class="space-y-2">
        <t-button variant="white" @click="createRandomEvent">{{ $t("modals.create_event.already_at_event") }}</t-button>
        <t-button variant="primary" @click="planNewEvent">{{ $t("modals.create_event.plan_event") }}</t-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import Repository from "repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import { modalTypes } from "../../utils/constants";

const EventRepository = Repository.get("my-events");

export default {
  name: "DeleteNote",
  components: {
    Modal,
  },
  emits: ['openEventFormPlease'],

  methods: {
    handleClose() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.CREATE_EVENT_MODAL,
        modalState: false,
      });
    },

    planNewEvent() {
      this.handleClose();
      if (this.$route.name == 'my-events') {
        console.log('please throw event');
        dispatchEvent(new CustomEvent("EVENT_OPEN_CREATE_FORM"));
        return;
      }
      this.$router.push({
        name: "my-events",
        params: { formOpenAtLoad: true },
      });
      
    },

    createRandomEvent() {
      const name = new Date().toDateString();
      const date_from = new Date().toISOString().split("T")[0];

      EventRepository.create({ name, date_from }).then((response) => {
        this.$router.push({
          name: "my-event",
          params: { uuid: response.data.event.uuid, autoCheckin: true },
        });
      });

      this.handleClose();
    },
  },

  computed: {
    ...mapGetters({
      createEventModalOpen: "createEventModalOpen",
    }),
  },
};
</script>
