import { ifNotAuthenticated } from "./routeGuards";

export const authRoutes = [
  {
    path: "/auth",
    name: "auth",
    meta: { layout: "application-default" },
    redirect: { name: "login" },
    component: () =>
      import(/* webpackChunkName: "Auth-index" */ "@/views/auth/Index.vue"),
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "login",
        name: "login",
        meta: { layout: "auth" },
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/auth/Login.vue")
      },
      {
        path: "register",
        name: "register",
        meta: { layout: "auth" },
        component: () =>
          import(/* webpackChunkName: "Register" */ "@/views/auth/Register.vue")
      },
      {
        path: "forgot",
        name: "forgot-password",
        meta: { layout: "auth" },
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "@/views/auth/Forgot.vue"
          )
      }
    ]
  }
];
