<template>
  <img
    :src="require(`assets/avatars/avatar_${getAvatar}.png`)"
    class="object-contain"
    :alt="getUsername"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Avatar",
  computed: {
    ...mapGetters(["getAvatar", "getUsername"])
  }
};
</script>

<style scoped></style>
