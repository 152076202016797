import api from "utils/api";
const resource = "/api/info";

export default {
  get(country, province) {
    if (country && province) {
      return api.get(
        `${resource}/assistance?country=${country}&province=${province}`
      );
    } else if (country) {
      return api.get(`${resource}/assistance?country=${country}`);
    } else if (province) {
      return api.get(`${resource}/assistance?province=${province}`);
    }

    return api.get(`${resource}/assistance`);
  }
};
