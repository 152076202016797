<template>
  <ul class="mobile-nav">
    <li>
      <router-link :to="{ name: 'home' }" exact :class="{
        'router-link-active': subIsActive(['/journal', '/extensive-journal', '/assessment']),
      }">
        <div class="navbar-item-icon">
          <icon-home />
        </div>
      </router-link>

      <nav-bar-active-button-curve />
    </li>


    <template v-if="!isAuthenticated">
      <li>
        <router-link :to="{ name: 'info-index' }">
          <div class="navbar-item-icon">
            <icon-info />
          </div>
        </router-link>

        <nav-bar-active-button-curve />
      </li>

      <li>
        <!-- <locale-switcher short></locale-switcher> -->
        <button type="button" class="navbar-item" @click="openChangeLocaleModal" :class="{
          'router-link-active': changeLocaleModalOpen,
        }">
          <div class="navbar-item-icon">
            <icon-globe class="w-5 h-5 fill-current" />
          </div>
        </button>
      </li>

      <li>
        <router-link :to="{ name: 'login' }" exact class="navbar-user-icon" :class="{
          'router-link-active': subIsActive(['/login', '/forgot-password', '/register']),
        }">
          <div class="navbar-item-icon">
            <icon-user class="router-link-active-hide" />
            <icon-user-fill class="router-link-active-show" />
          </div>
        </router-link>

        <nav-bar-active-button-curve />
      </li>
    </template>

    <template v-if="isAuthenticated && isProfileLoaded">
      <li>
        <router-link :to="{ name: 'statistics-index' }">
          <div class="navbar-item-icon">
            <icon-stats class="w-8 h-8" />
          </div>
        </router-link>
        <nav-bar-active-button-curve />
      </li>

      <li>
        <button type="button"
          class="absolute z-auto flex items-center justify-center w-16 h-16 p-1 text-white rounded-full -top-8 bg-brand-navblue"
          :class="{
            'border-brand-dark': currentEvent,
            'border-white': !currentEvent,
          }" style="border-width: 7px" @click="openCreateEventModal" v-if="!currentEvent">
          <icon-plus class="w-5 h-5 fill-current" />
        </button>

        <router-link v-else
          class="absolute flex items-center justify-center w-16 h-16 p-1 text-white border-4 rounded-full -top-8 border-brand-dark bg-brand-navblue"
          :to="{
            name: 'my-event',
            params: { uuid: currentEvent },
          }" v-slot="{ navigate }">
          <button type="button" @click="navigate" class="text-xs font-bold text-white truncate">
            {{ time }}
          </button>
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'my-events' }">
          <div class="navbar-item-icon">
            <icon-event-map-pin />

            <div class="navbar-item-icon__circle"></div>
          </div>
        </router-link>

        <nav-bar-active-button-curve />
      </li>
    </template>

    <template v-if="isAuthenticated">
      <li>
        <router-link :to="{ name: 'info-index' }">
          <div class="navbar-item-icon">
            <icon-info />
          </div>
        </router-link>

        <nav-bar-active-button-curve />
      </li>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import MyEventRepository from "../../repositories/MyEventRepository";
import { modalTypes } from "../../utils/constants";
import NavBarActiveButtonCurve from "@/assets/elements/navbar-active-button-curve.svg";
import IconEventMapPin from "@/assets/icons/event-map-pin.svg";
import IconHome from "@/assets/icons/home.svg";
import IconInfo from "@/assets/icons/info.svg";
import IconStats from "@/assets/icons/stats.svg";
import IconPlus from "@/assets/icons/plus.svg";
import IconUser from "@/assets/icons/user-circle.svg";

import IconGlobe from "@/assets/icons/globe.svg";
import IconUserFill from "@/assets/icons/user-circle-fill.svg";
// import LocaleSwitcher from "@/components/LocaleSwitcher";
// import ChangeLocale from "@/components/Modals/ChangeLocale";

export default {
  name: "MobileNav",

  components: {
    NavBarActiveButtonCurve,
    IconHome,
    IconStats,
    IconInfo,
    IconEventMapPin,
    IconPlus,
    IconUser,
    // LocaleSwitcher,
    IconUserFill,
    IconGlobe
    // ChangeLocale
  },

  props: {
    darkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      interval: null,
      time: "",
    };
  },

  mounted() {
    if (this.currentEvent) {
      this.startTimer();
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },

  watch: {
    currentEvent(newValue) {
      if (newValue && !this.time) {
        this.startTimer();
      }
    },
  },

  methods: {
    subIsActive(subRoute) {
      const paths = Array.isArray(subRoute) ? subRoute : [subRoute];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },

    secondsToTime(seconds) {
      const h = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, "0"),
        m = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0");

      return `${h}:${m}`;
    },

    async startTimer() {
      const { data } = await MyEventRepository.get(this.currentEvent);
      const checkins = data.event.log.filter((log) => log.type === "checkin").map((log) => new Date(log.created_at).getTime());
      const latestCheckin = Math.max(...checkins);

      this.interval = setInterval(() => {
        const date = Date.now();

        this.time = this.secondsToTime((date - latestCheckin) / 1000);
      }, 1000);
    },

    openChangeLocaleModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.CHANGE_LOCALE_MODAL,
        modalState: true,
      });
    },

    openCreateEventModal() {
      if (this.currentEvent) {
        return;
      }

      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.CREATE_EVENT_MODAL,
        modalState: true,
      });
    },
  },

  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded", "isCheckedIn", "currentEvent", "changeLocaleModalOpen"]),
  },
};
</script>



<style scoped>
.navbar-user-icon .router-link-active-hide {
  display: none;
}

.navbar-user-icon .router-link-active-show {
  display: flex;
}

.navbar-user-icon.router-link-active .router-link-active-show {
  display: none;
}

.navbar-user-icon.router-link-active .router-link-active-hide {
  display: flex;
}
</style>
