var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"mobile-nav"},[_c('li',[_c('router-link',{class:{
      'router-link-active': _vm.subIsActive(['/journal', '/extensive-journal', '/assessment']),
    },attrs:{"to":{ name: 'home' },"exact":""}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-home')],1)]),_c('nav-bar-active-button-curve')],1),(!_vm.isAuthenticated)?[_c('li',[_c('router-link',{attrs:{"to":{ name: 'info-index' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-info')],1)]),_c('nav-bar-active-button-curve')],1),_c('li',[_c('button',{staticClass:"navbar-item",class:{
        'router-link-active': _vm.changeLocaleModalOpen,
      },attrs:{"type":"button"},on:{"click":_vm.openChangeLocaleModal}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-globe',{staticClass:"w-5 h-5 fill-current"})],1)])]),_c('li',[_c('router-link',{staticClass:"navbar-user-icon",class:{
        'router-link-active': _vm.subIsActive(['/login', '/forgot-password', '/register']),
      },attrs:{"to":{ name: 'login' },"exact":""}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-user',{staticClass:"router-link-active-hide"}),_c('icon-user-fill',{staticClass:"router-link-active-show"})],1)]),_c('nav-bar-active-button-curve')],1)]:_vm._e(),(_vm.isAuthenticated && _vm.isProfileLoaded)?[_c('li',[_c('router-link',{attrs:{"to":{ name: 'statistics-index' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-stats',{staticClass:"w-8 h-8"})],1)]),_c('nav-bar-active-button-curve')],1),_c('li',[(!_vm.currentEvent)?_c('button',{staticClass:"absolute z-auto flex items-center justify-center w-16 h-16 p-1 text-white rounded-full -top-8 bg-brand-navblue",class:{
          'border-brand-dark': _vm.currentEvent,
          'border-white': !_vm.currentEvent,
        },staticStyle:{"border-width":"7px"},attrs:{"type":"button"},on:{"click":_vm.openCreateEventModal}},[_c('icon-plus',{staticClass:"w-5 h-5 fill-current"})],1):_c('router-link',{staticClass:"absolute flex items-center justify-center w-16 h-16 p-1 text-white border-4 rounded-full -top-8 border-brand-dark bg-brand-navblue",attrs:{"to":{
          name: 'my-event',
          params: { uuid: _vm.currentEvent },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('button',{staticClass:"text-xs font-bold text-white truncate",attrs:{"type":"button"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.time)+" ")])]}}],null,false,255987963)})],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'my-events' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-event-map-pin'),_c('div',{staticClass:"navbar-item-icon__circle"})],1)]),_c('nav-bar-active-button-curve')],1)]:_vm._e(),(_vm.isAuthenticated)?[_c('li',[_c('router-link',{attrs:{"to":{ name: 'info-index' }}},[_c('div',{staticClass:"navbar-item-icon"},[_c('icon-info')],1)]),_c('nav-bar-active-button-curve')],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }