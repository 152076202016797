import api from "utils/api";
const resource = "/api/user";

export default {
  get() {
    return api.get(`${resource}`);
  },
  register({ email, name, password, password_confirmation, enable_analytics }) {
    return api.post("/api/register", {
      email,
      name,
      password,
      password_confirmation,
      enable_analytics,
    });
  },
  updateEmail(email, password) {
    // needs pw verfication
    return api.put(`${resource}/email`, { email, password });
  },
  updateName(name) {
    return api.put(`${resource}/name`, { name });
  },
  updateAvatar(avatar) {
    return api.put(`${resource}/avatar`, { avatar });
  },
  updateLanguage(language) {
    return api.put(`${resource}/language`, { language });
  },
  updatePhoneNumber(phone) {
    return api.put(`${resource}/phone`, { phone });
  },

  addBuddy(name, phone) {
    return api.post(`${resource}/buddy`, { name, phone });
  },
  updateBuddy(identifier, name, phone) {
    return api.put(`${resource}/buddy/${identifier}`, { name, phone });
  },
  forgetBuddy(identifier) {
    return api.delete(`${resource}/buddy/${identifier}`);
  },
  updateAnalytics(enabled) {
    return api.put(`${resource}/analytics`, { enabled });
  },
  bulkAddTodos(items) {
    return api.post(`${resource}/todos/bulk`, { items });
  },
  delete() {
    return api.delete(`${resource}/delete`);
  },

  async updateNotificationPreference(settings) {
    try {
      const { data } = await api.put(`${resource}/modal-preferences`, settings);
      return data;
    } catch (e) {
      return e.message;
    }
  },

  validateData(data, validationType) {
    return api.post(`/api/validation`, data, {
      params: {
        type: validationType,
      },
    });
  },
};
