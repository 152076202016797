<template>
  <portal to="topbar-left">
    <router-link
      :to="{ name: routerName }"
      class="relative font-bold text-brand-dark"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 22"
        class="w-6 h-6"
      >
        <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
          <path
            d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
          />
        </g>
      </svg>
    </router-link>
  </portal>
</template>

<script>
export default {
  name: "PortalBack",

  props: {
    routerName: {
      required: true
    }
  }
};
</script>

<style scoped></style>
