<template>
<svg viewBox="0 0 200 200" class="transform-gpu">
    <template as="defs">
        <linearGradient
            v-for="(mood,mood_i) in moods"
            :key="`--${mood_i}-background`"
            :x1="mood.backgroundGradient.x1" :y1="mood.backgroundGradient.y1"
            :x2="mood.backgroundGradient.x2" :y2="mood.backgroundGradient.y2"
            :id="`mood-circle-${mood.value}`"
        >
            <stop v-for="(stop,stop_i) in mood.backgroundGradient.stops"
                :key="`--${mood_i}-background-stop-${stop_i}`"
                :stop-color="stop.color" :offset="stop.offset"
            />
        </linearGradient>
    </template>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g opacity="0.1" :fill="shadowColor">
            <circle cx="100" cy="100" r="100"></circle>
        </g>
        <g transform="translate(15.000000, 15.000000)" opacity="0.3" :fill="shadowColor">
            <circle cx="85" cy="85" r="85"></circle>
        </g>
        <g
            transform="translate(30.000000, 30.000000)"
            fill="none" fill-rule="evenodd"
        >
            <g v-for="(mood, mood_i) in moods"
                :key="`--${mood_i}-face`"
                fill="#FFF" fill-rule="nonzero"
                class="transition duration-100"
                v-show="mood.value == value"
            >
                <circle :fill="`url(#mood-circle-${value})`" cx="70" cy="70" r="70"></circle>
                <template v-if="mood.facePaths">
                    <path
                        transform="scale(2.25)"
                        v-for="(face_path, face_path_i) in mood.facePaths"
                        :key="`--${mood_i}-${face_path_i}`" :d="face_path"
                    />
                </template>
                <g v-if="label && label.length">
                    <text x="30" y="40" fill="#111111" transform="scale(2)">{{ label }}</text>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'mood-icon',
    props: {
        value: {
            type: String,
            default: function () {
                return this.default 
            },
        },
        shadowColor: {
            type: String,
            default: '#FFFFFF'
        },
        label: {
            type: String,
            default: null
        },
        default: {
            type: [String, undefined],
            /* default: function () {
                const [firstDefault] = this.moods.find((m)=>{ return m })
                if(firstDefault) {
                    return firstDefault.value
                }
                const [first] = this.moods
                return first.value || undefined;
            }, */
            default: 'neutral'
        }
    },
    data: () => ({
        moods: [
            {
                value: 'undefined',
                facePaths: null,
                backgroundGradient: {
                    x1: '50%',
                    y1: '3.75%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#EEEEEE', offset: '0%' },
                        { color: '#FFFFFF', offset: '100%' }
                    ]
                },
            },
            {
                value: 'angry',
                facePaths: [
                    "M23.707 25.937l-1.83-3.167a.609.609 0 00-1.046.622l1.349 2.343h-2.666a.606.606 0 000 1.212h3.636a.606.606 0 00.646-.606v-.044a.602.602 0 00-.089-.36zm16.899-.202h-2.67l1.37-2.343a.606.606 0 00-1.05-.606l-1.827 3.151a.578.578 0 00-.077.364.606.606 0 00.606.646h3.636a.606.606 0 100-1.212h.012zm-1.757 16.568a.594.594 0 01-.343-.105 15.328 15.328 0 00-9.733-2.707 15.142 15.142 0 00-7.563 2.703.606.606 0 11-.694-.994 16.362 16.362 0 018.172-2.917c3.73-.24 7.433.788 10.504 2.917a.606.606 0 01-.343 1.103z"
                ],
                backgroundGradient: {
                    x1: '50%',
                    y1: '3.75%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#7AA5FF', offset: '0%' },
                        { color: '#540FB0', offset: '100%' }
                    ]
                },
            /** <linearGradient x1="50%" y1="3.75%" x2="50%" y2="99.72%" id="mood-circle-neutral">
                    <stop stop-color="#7AA5FF" offset="0%"/>
                    <stop stop-color="#540FB0" offset="100%"/>
                </linearGradient> */
            },
            {
                value: 'sad',
                facePaths: [
                    "M38.57 23.91a.606.606 0 00-.606.605v3.636a.606.606 0 101.212 0v-3.636a.606.606 0 00-.606-.606zM21.142 23.91a.606.606 0 00-.606.605v3.636a.606.606 0 101.212 0v-3.636a.606.606 0 00-.606-.606zM39.192 41.2a16.564 16.564 0 00-10.504-2.917 16.213 16.213 0 00-3.83.76 7.272 7.272 0 00-1.098-3.151c-.772-1.188-1.713-1.863-2.618-1.863-.905 0-1.855.675-2.598 1.863-.889 1.381-1.616 3.68-.723 5.615.557 1.212 1.806 2.424 3.337 2.424 1.531 0 2.783-1.212 3.341-2.424.17-.375.287-.772.347-1.18a14.9 14.9 0 013.943-.836c3.457-.223 6.888.731 9.733 2.707a.61.61 0 00.84-.153.602.602 0 00-.17-.845zm-15.8-.198c-.275.602-1.095 1.738-2.238 1.738s-1.96-1.136-2.234-1.738c-.639-1.393-.138-3.232.638-4.444.513-.808 1.14-1.305 1.616-1.305.477 0 1.087.514 1.616 1.305.727 1.2 1.228 3.05.602 4.444z"
                ],
                backgroundGradient: {
                    x1: '50%',
                    y1: '3.75%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#14CDE3', offset: '0%' },
                        { color: '#3A51AA', offset: '100%' }
                    ]
                },
            /** <linearGradient x1="50%" y1="3.33%" x2="50%" y2="99.72%" id="mood-circle-sad">
                    <stop stop-color="#14CDE3" offset="0%"/>
                    <stop stop-color="#3A51AA" offset="100%"/>
                </linearGradient> */
            },
            {
                default: true,
                value: 'neutral',
                facePaths: [
                    "M22.681 24.49a.602.602 0 00-.844.15l-.554.772-.55-.788a.606.606 0 10-.977.711l.808 1.148-.808 1.151a.602.602 0 00.15.84c.1.074.222.114.347.113a.614.614 0 00.497-.258l.55-.788.553.788a.602.602 0 00.844.145.598.598 0 00.146-.84l-.808-1.151.808-1.148a.602.602 0 00-.162-.844zM39.447 26.483l.808-1.148a.606.606 0 00-.994-.695l-.553.772-.55-.788a.606.606 0 00-.994.695l.808 1.147-.808 1.152a.602.602 0 00.15.84c.1.074.222.114.347.113a.614.614 0 00.497-.258l.55-.788.553.788c.115.16.3.257.497.258.125 0 .247-.04.347-.113a.602.602 0 00.15-.84l-.808-1.135zM38.849 39.455H20.863a.606.606 0 110-1.212h17.986a.606.606 0 110 1.212z"
                ],
                backgroundGradient: {
                    x1: '50%',
                    y1: '2.23%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#2BFDA1', offset: '0%' },
                        { color: '#0C9DAF', offset: '100%' }
                    ]
                },
            /** <linearGradient x1="50%" y1="2.231%" x2="50%" y2="99.716%" id="mood-circle-neutral">
                    <stop stop-color="#2BFDA1" offset="0%"/>
                    <stop stop-color="#0C9DAF" offset="100%"/>
                </linearGradient> */
            },
            {
                value: 'good',
                facePaths: [
                    "M29.832 42.307c-.372 0-.756 0-1.144-.044a16.322 16.322 0 01-8.172-2.917.611.611 0 11.694-1.006 15.122 15.122 0 007.563 2.703c3.456.222 6.886-.73 9.733-2.703a.606.606 0 11.686.994 16.604 16.604 0 01-9.36 2.973zM21.142 23.91a.606.606 0 00-.606.605v3.636a.606.606 0 101.212 0v-3.636a.606.606 0 00-.606-.606zM41.911 27.028a3.79 3.79 0 00-6.686 0 .606.606 0 101.067.574 2.582 2.582 0 014.553 0 .61.61 0 00.82.246.606.606 0 00.246-.82z"
                ],
                backgroundGradient: {
                    x1: '50%',
                    y1: '2.23%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#ECE96B', offset: '0%' },
                        { color: '#5AB420', offset: '100%' }
                    ]
                },
            /** <linearGradient x1="50%" y1="2.231%" x2="50%" y2="99.716%" id="mood-circle-good">
                    <stop stop-color="#ECE96B" offset="0%"/>
                    <stop stop-color="#5AB420" offset="100%"/>
                </linearGradient> */
            },
            {
                value: 'satisfied',
                facePaths: [
                    "M29.856 42.303c-.376 0-.755 0-1.147-.04a16.322 16.322 0 01-8.173-2.917.606.606 0 11.674-1.006 15.106 15.106 0 007.563 2.703 15.324 15.324 0 009.733-2.703.606.606 0 01.94.606.598.598 0 01-.254.388 16.6 16.6 0 01-9.336 2.97zM18.895 28.495a.606.606 0 01-.533-.88 3.794 3.794 0 016.69-.001.607.607 0 11-1.07.573 2.582 2.582 0 00-4.553 0 .61.61 0 01-.534.308zM41.378 28.495a.61.61 0 01-.533-.32 2.582 2.582 0 00-4.553 0 .606.606 0 11-1.067-.573 3.79 3.79 0 016.686 0c.15.291.041.65-.246.808a.618.618 0 01-.287.085z"
                ],
                backgroundGradient: {
                    x1: '50%',
                    y1: '2.23%',
                    x2: '50%',
                    y2: '99.72%',
                    stops: [
                        { color: '#FFC330', offset: '0%' },
                        { color: '#EF942A', offset: '100%' }
                    ]
                },
            /** <linearGradient x1="50%" y1="2.231%" x2="50%" y2="99.716%" id="mood-circle-satisfied">
                    <stop stop-color="#FFC330" offset="0%"/>
                    <stop stop-color="#EF942A" offset="100%"/>
                </linearGradient>*/
            }
        ],
    }),
}
</script>