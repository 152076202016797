<template>
  <div class="relative flex flex-col overflow-hidden select-none">
    <div
      class="relative z-10 flex items-center justify-center w-32 h-32 mx-auto"
    >
      <mood-icon :value="selectedMood" class="w-full h-full" />
    </div>

    <div class="px-12 mt-10 mb-4">
      <vue-slider
        v-model="selectedMood"
        :data="moods"
        tooltip="always"
        :marks="false"
        dot-options="none"
        :hide-label="true"
        :mark-style="{ display: 'none' }"
        :dot-style="{ backgroundColor: 'white' }"
      >
        <template v-slot:dot>
          <div
            class="absolute grid w-8 h-8 bg-white rounded-full shadow-md place-items-center -top-2 -left-2"
          >
            <img src="@/assets/backgrounds/ic_drag.png" class="w-4" />
          </div>
        </template>

        <template v-slot:tooltip="{ value, focus }">
          <div
            :class="[
              'custom-tooltip rounded-md bg-white text-black text-xs px-2 py-1 shadow-md whitespace-nowrap',
              { focus }
            ]"
          >
            {{ $t(`moods.${value}`) }}
          </div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import MoodIcon from "components/MoodIcon";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "MoodSelector",
  components: {
    MoodIcon,
    VueSlider
  },
  props: {
    mood: {
      type: String,
      required: false
    }
  },
  watch: {
    mood(newVal) {
      this.selectedMood = newVal;
    },

    selectedMood(newVal) {
      this.$emit("update", newVal);
    }
  },
  data() {
    return {
      selectedMood: this.mood || "neutral",
      moods: ["angry", "sad", "neutral", "good", "satisfied"]
    };
  }
};
</script>

<style lang="postcss" scoped>
.mood-selector-item {
  @apply transition duration-200;
}

.mood-selector-enter-from,
.mood-selector-leave-to {
  opacity: 1;
}

.mood-selector-leave-active {
  /* position: absolute; */
  opacity: 0;
}

.custom-tooltip {
  display: none; /** removed after study */
  
  transform: translateY(-0.5rem);
  font-size: 0.625rem;
}

.custom-tooltip.focus {
  @apply rounded-lg font-bold;
}
</style>
