<template>
  <div
    class="flex flex-col pt-4 bg-image layout-wrapper bg-brand-lightblue text-brand-dark"
  >  
    
    <portal to="topbar-left">
      <div
        @click="goBack"
        class="relative font-bold"
        key="info-drugcombos-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          class="w-6 h-6"
        >
          <g
            fill="currentColor"
            fill-rule="evenodd"
            transform="translate(-4 -1)"
          >
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
            />
          </g>
        </svg>
      </div>
    </portal>

    <top-bar></top-bar>
    <div class="layout-content">
      <slot class="layout-content-slot" />
    </div>
    <nav-bar class="hidden layout-navbar md:flex" />
    <!-- <div
      class="flex flex-col items-center justify-center pt-4 pb-10 text-center select-none md:hidden"
    >
      <logo class="w-16 mx-auto mb-4" type="dark-stacked" />
    </div> -->
  </div>
</template>

<script>
// import ApplicationLogo from "@/components/ApplicationLogo";
import NavBar from '@/layouts/partials/NavBar'
import TopBar from '@/layouts/partials/TopBar'
import { mapGetters } from "vuex";

export default {
  name: "AuthLayout",
  components: {
    NavBar,TopBar,
    // logo: ApplicationLogo,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
};
</script>

<style scoped>
/* .bg-image {
  @apply bg-cover bg-center bg-no-repeat;
  background-image: url(../assets/backgrounds/bg_app_geometric.png);
} */

.layout-wrapper {
  @apply relative flex flex-col bg-brand-lightblue text-brand-dark bg-cover bg-no-repeat bg-center;
    /*background-image: url(../assets/backgrounds/bg_app_geometric_clean.png);*/
    background-image: url(../assets/backgrounds/bg_app_geometric.png);
}
.layout-topbar {
  @apply flex-shrink-0;
}
.layout-content {
  @apply overflow-hidden overflow-y-auto absolute inset-0;
}
.layout-navbar {
  @apply flex-shrink-0 mt-auto;
}
</style>
