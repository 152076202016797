export default {
  state: {
    checkListModalOpen: false,
    deleteNoteModalOpen: false,
    dailyMoodModalOpen: false,
    dontForgetCheckoutModalOpen: false,
    createEventModalOpen: false,
    twoDaysAfterEventModalOpen: false,
    tutorialModalOpen: false,
    changeLocaleModalOpen: false,
  },

  mutations: {
    TOGGLE_CHECKLIST_MODAL: (state, payload) =>
      (state.checkListModalOpen = payload),

    TOGGLE_DELETE_NOTE_MODAL: (state, payload) =>
      (state.deleteNoteModalOpen = payload),

    TOGGLE_MODAL: (state, payload) =>
      (state[payload.modalType] = payload.modalState),
  },

  commits: {},

  getters: {
    checkListModalOpen: (state) => state.checkListModalOpen,
    deleteNoteModalOpen: (state) => state.deleteNoteModalOpen,
    dailyMoodModalOpen: (state) => state.dailyMoodModalOpen,
    dontForgetCheckoutModalOpen: (state) => state.dontForgetCheckoutModalOpen,
    createEventModalOpen: (state) => state.createEventModalOpen,
    twoDaysAfterEventModalOpen: (state) => state.twoDaysAfterEventModalOpen,
    tutorialModalOpen: (state) => state.tutorialModalOpen,
    changeLocaleModalOpen: (state) => state.changeLocaleModalOpen
  },
};
