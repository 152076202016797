import image from "assets/emergency/budd-recovery-position.png";

const emergencyArticles = [
  {
    id: "bad-trip",
    title: "emergency_articles.articles.bad-trip.title",
    content: "emergency_articles.articles.bad-trip.content",
  },
  {
    id: "heatstroke",
    title: "emergency_articles.articles.heatstroke.title",
    content: "emergency_articles.articles.heatstroke.content",
  },
  {
    id: "when-to-call-emergency-services",
    title: "emergency_articles.articles.when-to-call-emergency-services.title",
    content: "emergency_articles.articles.when-to-call-emergency-services.content",
  },
  {
    id: "recovery-position",
    title: "emergency_articles.articles.recovery-position.title",
    content: "emergency_articles.articles.recovery-position.content",
    image: image
  },
  {
    id: "unconsciousness",
    title: "emergency_articles.articles.unconsciousness.title",
    content: "emergency_articles.articles.unconsciousness.content",
  }
];

export default {
  entries() {
    return emergencyArticles.map(a => {
      return { id: a.id, title: a.title };
    });
  },
  get(key) {
    return emergencyArticles.find(a => a.id == key);
  }
};
