
const dosage_keys = [
  'threshold', 'light', 'common', 'strong', 'heavy'
];

const administration_methods = [
  'oral', 'insufflated', 'sublingual', 'smoking'
];

const duration_keys = [
  'total', 'onset', 'come_up', 'peak', 'offset', 'after_effects'
];

/* const psychoactive_classes = [
  'entactogen',
];
const chemical_classess = [
  'entactogen',
]; */

const danger_risk__serotonin = 'serotonin_risk';

const chems = {
  ghb : {
    key: 'ghb',
    description: 'cheminfo.chem_info.ghb.description',
    links: [
      { url: 'https://psychonautwiki.org/wiki/GHB', title: 'GHB on Psychonautwiki' },
      // { url: 'https://psychonautwiki.org/wiki/GBL', title: 'GBL on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 0.5,
            entity: 'ml'
          },
          light: {
            value: [0.5,1],
            entity: 'ml'
          },
          common: {
            value: [1,2.5],
            entity: 'ml'
          },
          strong: {
            value: [2.5,4],
            entity: 'ml'
          },
          heavy: {
            value: 4,
            entity: 'ml',
            label_after: '+',
            warning: 'cheminfo.administration_dosage_warnings.ghb_heavy'
          }
        },
        duration: {
          total: {
            value: [(1.5*60*60),(2.5*60*60)],
            // label_after: 'hours',
          },
          onset: {
            value: [(5*60),(30*60)],
            // label_after: 'minutes',
          },
          come_up: {
            value: [(10*60),(20*60)],
            // label_after: 'minutes',
          },
          peak: {
            value: [(45*60),(90*60)],
            // label_after: 'minutes',
          },
          offset: {
            value: [(15*60),(30*60)],
            // label_after: 'minutes',
          },
          after_effects: {
            value: [(2*60*60),(4*60*60)],
            // label_after: 'hours',
          }
        }
      },
    },
    effects: ['stimulation_and_sedation', 'respiratory_depression', 'muscle_relaxation', 'dizziness', 'nausea', 'cognitive_euphoria', 'disinhibition', 'empathy_affection_and_sociability_enhancement', 'increased_libido'],
  },
        
  gbl : {
    key: 'gbl',
    description: 'cheminfo.chem_info.gbl.description',
    links: [
      // { url: 'https://psychonautwiki.org/wiki/GHB', title: 'GHB on Psychonautwiki' },
      { url: 'https://psychonautwiki.org/wiki/GBL', title: 'GBL on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 0.3,
            entity: 'ml'
          },
          light: {
            value: [0.3,0.9],
            entity: 'ml'
          },
          common: {
            value: [0.9,1.5],
            entity: 'ml'
          },
          strong: {
            value: [1.5,3],
            entity: 'ml',
            warning: 'cheminfo.administration_dosage_warnings.gbl_strong'
          },
          heavy: {
            value: 3,
            entity: 'ml',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [1*60*60,2*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [3*60,10*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [5*60,15*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [30*60,45*60],
            // label_after: 'minutes',
          },
          offset: {
            value: [15*60,30*60],
            // label_after: 'minutes',
          },
          after_effects: {
            value: [1*60*60,3*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects: ['stimulation_and_sedation', 'respiratory_depression', 'muscle_relaxation', 'dizziness', 'nausea', 'cognitive_euphoria', 'disinhibition', 'empathy_affection_and_sociability_enhancement', 'increased_libido'],
  },

  
  mdma : {
    key: 'mdma',
    // psychoactive_classes: ['entactogen', 'stimulant'],
    // chemical_classes: ['amphetamines', 'mdxx'],
    // common_names: ['MDMA', 'Molly', 'Mandy', 'Emma', 'MD', 'Ecstasy', 'E', 'X', 'XTC', 'Rolls', 'Beans'],
    // substitutive_name: '3,4-Methylenedioxy-N-methylamphetamine',
    
    description: 'cheminfo.chem_info.mdma.description',
    links: [
      // { url: 'https://psychonautwiki.org/wiki/GHB', title: 'GHB on Psychonautwiki' },
      { url: 'https://psychonautwiki.org/wiki/MDMA', title: 'MDMA on Psychonautwiki' },
      // { url: 'https://drugs.tripsit.me/mdma', title: 'MDMA on TripSit' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 25,
            entity: 'mg'
          },
          light: {
            value: [45,75],
            entity: 'mg'
          },
          common: {
            value: [75,140],
            entity: 'mg'
          },
          strong: {
            value: [140,180],
            entity: 'mg',
          },
          heavy: {
            value: 180,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [3*60*60,6*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [30*60,60*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [15*60,30*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [1.56*60*60,2.56*60*60],
            // label_after: 'hours',
          },
          offset: {
            value: [16*60*60,1.56*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [12*60*60,48*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: 'cheminfo.chem_info.mdma.effects_introduction',
    danger_risk: danger_risk__serotonin
  },

  cocaine : {
    key: 'cocaine',
    description: 'cheminfo.chem_info.cocaine.description',
    links: [
      { url: 'https://psychonautwiki.org/wiki/Cocaine', title: 'Cocaine on Psychonautwiki' },
    ],
    administration: {
      insufflated: {
        dosage: {
          threshold: {
            value: 5,
            entity: 'mg'
          },
          light: {
            value: [10,30],
            entity: 'mg'
          },
          common: {
            value: [30,60],
            entity: 'mg'
          },
          strong: {
            value: [60,90],
            entity: 'mg',
          },
          heavy: {
            value: 90,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          /* total: {
            value: [10*60,90*60],
          }, */
          peak: {
            value: [10*60,90*60],
            // label_after: 'hours',
          },
          onset: {
            value: [15,45],
            // label_after: 'seconds',
          },
        }
      },
    },
    effects_introduction: 'cheminfo.chem_info.cocaine.effects_introduction',
  },

  methamphetamine : {
    key: 'methamphetamine',
    description: 'cheminfo.chem_info.methamphetamine.description',
    links: [
      { url: 'https://psychonautwiki.org/wiki/Methamphetamine', title: 'Methamphetamine on Psychonautwiki' },
    ],
    administration: {
      smoking: {
        dosage: {
          threshold: {
            label_before: '<',
            value: 5,
            entity: 'mg'
          },
          light: {
            value: [5,10],
            entity: 'mg'
          },
          common: {
            value: [10,20],
            entity: 'mg'
          },
          strong: {
            value: [20,60],
            entity: 'mg',
          },
          heavy: {
            value: 60,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [2*60*60,6*60*60],
            // label_after: 'hours',
            warning: 'cheminfo.administration_duration_warnings.methamphetamine_total'
          },
          onset: {
            value: [7,10],
            // label_after: 'seconds',
          },
          come_up: {
            value: [5,10],
            // label_after: 'seconds',
          },
          peak: {
            value: [1*60*60,3*60*60],
            // label_after: 'hours',
          },
          offset: {
            value: [1*60*60,3*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [2*60*60,18*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: 'cheminfo.chem_info.methamphetamine.effects_introduction',
  },

  ketamine : {
    key: 'ketamine',
    description: 'cheminfo.chem_info.ketamine.description',
    links: [
      { url: 'https://psychonautwiki.org/wiki/Ketamine', title: 'Ketamine on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          /* bioavailability: {
            value: 17,
            entity: '%'
          }, */
          threshold: {
            value: 50,
            entity: 'mg'
          },
          light: {
            value: [50,100],
            entity: 'mg'
          },
          common: {
            value: [100,300],
            entity: 'mg'
          },
          strong: {
            value: [300,450],
            entity: 'mg',
          },
          heavy: {
            value: 450,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          onset: {
            value: [10*60,30*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [5*60,20*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [45*60,90*60],
            // label_after: 'minutes',
          },
          offset: {
            value: [3*60*60,6*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [4*60*60,8*60*60],
            // label_after: 'hours',
          }
        }
      },
      insufflated: {
        dosage: {
          /* bioavailability: {
            value: 45,
            entity: '%'
          }, */
          threshold: {
            value: 5,
            entity: 'mg'
          },
          light: {
            value: [10,30],
            entity: 'mg'
          },
          common: {
            value: [30,75],
            entity: 'mg'
          },
          strong: {
            value: [75,150],
            entity: 'mg',
          },
          heavy: {
            value: 150,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [1*60*60,1.5*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [2*60,5*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [15*60,30*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [1.5*60,2.5*60],
            // label_after: 'hours',
          },
          offset: {
            value: [1*60*60,1.5*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [12*60*60,48*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: 'cheminfo.chem_info.ketamine.effects_introduction',
  },


  fourmmc : {
    key: 'fourmmc',
    description: "cheminfo.chem_info.fourmmc.description",
    links: [
      { url: 'https://psychonautwiki.org/wiki/Mephedrone', title: 'Mephedrone on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 15,
            entity: 'mg'
          },
          light: {
            value: [50,100],
            entity: 'mg'
          },
          common: {
            value: [100,200],
            entity: 'mg'
          },
          strong: {
            value: [200,300],
            entity: 'mg',
          },
          heavy: {
            value: 300,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [4*60*60,8*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [15*60,45*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [15*60,30*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [2*60*60,4*60*60],
            // label_after: 'hours',
          },
          offset: {
            value: [30*60,90*60],
            // label_after: 'minutes',
          },
          after_effects: {
            value: [2*60*60,4*60*60],
            // label_after: 'hours',
          }
        }
      },
      insufflated: {
        dosage: {
          threshold: {
            value: 5,
            entity: 'mg'
          },
          light: {
            value: [15,45],
            entity: 'mg'
          },
          common: {
            value: [45,80],
            entity: 'mg'
          },
          strong: {
            value: [80,125],
            entity: 'mg',
          },
          heavy: {
            value: 125,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [3*60*60,6*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [15*60,45*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [15*60,30*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [30*60,60*60],
            // label_after: 'minutes',
          },
          offset: {
            value: [30*60,90*60],
            // label_after: 'minutes',
          },
          after_effects: {
            value: [2*60*60,4*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: 'cheminfo.chem_info.fourmmc.effects_introduction',
    danger_risk: danger_risk__serotonin
  },


  threemmc : {
    key: 'threemmc',
    description: "cheminfo.chem_info.threemmc.description",
    links: [
      { url: 'https://psychonautwiki.org/wiki/3-MMC', title: '3-MMC on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 50,
            entity: 'mg'
          },
          light: {
            value: [50,150],
            entity: 'mg'
          },
          common: {
            value: [150,250],
            entity: 'mg'
          },
          strong: {
            value: [250,350],
            entity: 'mg',
          },
          heavy: {
            value: 350,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [4*60*60,6*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [10*60,30*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [30*60,60*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [2*60*60,3*60*60],
            // label_after: 'hours',
          },
          offset: {
            value: [1*60*60,1.5*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [2*60*60,4*60*60],
            // label_after: 'hours',
          }
        },
      },
      insufflated: {
        dosage: {
          threshold: {
            value: 20,
            entity: 'mg'
          },
          light: {
            value: [20,40],
            entity: 'mg'
          },
          common: {
            value: [40,60],
            entity: 'mg'
          },
          strong: {
            value: [60,120],
            entity: 'mg',
          },
          heavy: {
            value: 120,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [2.5*60*60,4.5*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [5*60,10*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [10*60,20*60],
            // // label_after: 'minutes',
          },
          peak: {
            value: [1*60*60,1.5*60*60],
            // // label_after: 'hours',
          },
          offset: {
            value: [1*60*60,2*60*60],
            // // label_after: 'hours',
          },
          after_effects: {
            value: [1*60*60,1.5*60*60],
            // // label_after: 'hours',
          }
        },
      }
    },
    effects_introduction: "cheminfo.chem_info.threemmc.effects_introduction",
    danger_risk: danger_risk__serotonin
  },


  poppers : {
    key: 'poppers',
    description: "cheminfo.chem_info.poppers.description",
    links: [
      { url: 'https://psychonautwiki.org/wiki/Poppers', title: 'Poppers on Psychonautwiki' },
    ],
    dosage_description: "cheminfo.chem_info.poppers.dosage_description",
    duration_description: "cheminfo.chem_info.poppers.duration_description",
    effects: ['headaches', 'changes_in_gravity', 'decreased_blood_pressure', 'increased_heart_rate', 'physical_euphoria', 'muscle_relaxation', 'vasodilation', 'disinhibition', 'increased_libido', 'increased_music_appreciation'],
    avoid_interactions: 'cheminfo.chem_info.poppers.avoid_interactions',
  },



  cannabis : {
    key: 'cannabis',
    description: "cheminfo.chem_info.cannabis.description",
    links: [
      { url: 'https://psychonautwiki.org/wiki/Cannabis', title: 'Cannabis on Psychonautwiki' },
    ],
    administration: {
      smoking: {
        dosage: {
          threshold: {
            value: 25,
            entity: 'mg'
          },
          light: {
            value: [33,66],
            entity: 'mg'
          },
          common: {
            value: [66,100],
            entity: 'mg'
          },
          strong: {
            value: [100,150],
            entity: 'mg',
          },
          heavy: {
            value: 150,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [1*60*60,4*60*60],
            label_after: 'hours',
          },
          onset: {
            value: [0*60,10*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [5*60,10*60],
            // // label_after: 'minutes',
          },
          peak: {
            value: [15*60,30*60],
            // // label_after: 'minutes',
          },
          after_effects: {
            value: [45*60,180*60],
            // // label_after: 'minutes',
          }
        }
      },
      oral: {
        dosage: {
          threshold: {
            value: 1,
            entity: 'mg',
            label_after: '(THC)',
          },
          light: {
            value: [2.5,5],
            entity: 'mg'
          },
          common: {
            value: [5,10],
            entity: 'mg'
          },
          strong: {
            value: [10,25],
            entity: 'mg',
          },
          heavy: {
            value: 25,
            entity: 'mg',
            label_after: '+ (THC)',
          }
        },
        duration: {
          total: {
            value: [4*60*60,10*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [20*60,120*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [20*60,40*60],
            // // label_after: 'minutes',
          },
          peak: {
            value: [2*60*60,5*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [6*60*60,12*60*60],
            // // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: "cheminfo.chem_info.cannabis.effects_introduction",
  },



  amphetamine : {
    key: 'amphetamine',
    description: "cheminfo.chem_info.amphetamine.description",
    links: [
      { url: 'https://psychonautwiki.org/wiki/Amphetamine', title: 'Amphetamine on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 5,
            entity: 'mg'
          },
          light: {
            value: [7.5,20],
            entity: 'mg'
          },
          common: {
            value: [20,40],
            entity: 'mg'
          },
          strong: {
            value: [40,70],
            entity: 'mg',
          },
          heavy: {
            value: 70,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [6*60*60,8*60*60],
            // label_after: 'hours',
          },
          onset: {
            value: [35*60,45*60],
            // label_after: 'minutes',
          },
          come_up: {
            value: [30*60,135*60],
            // label_after: 'minutes',
          },
          peak: {
            value: [2.5*60*60,4*60*60],
            // label_after: 'hours',
          },
          offset: {
            value: [2*60*60,3*60*60],
            // label_after: 'hours',
          },
          after_effects: {
            value: [3*60*60,6*60*60],
            // label_after: 'hours',
          }
        }
      },
    },
    effects_introduction: "cheminfo.chem_info.amphetamine.effects_introduction",
  },






/************
  chem_key : {
    key: 'chem_key',
    description: "",
    links: [
      { url: 'https://psychonautwiki.org/wiki/Cocaine', title: 'Cocaine on Psychonautwiki' },
    ],
    administration: {
      oral: {
        dosage: {
          threshold: {
            value: 25,
            entity: 'mg'
          },
          light: {
            value: [45,75],
            entity: 'mg'
          },
          common: {
            value: [75,140],
            entity: 'mg'
          },
          strong: {
            value: [140,180],
            entity: 'mg',
          },
          heavy: {
            value: 180,
            entity: 'mg',
            label_after: '+',
          }
        },
        duration: {
          total: {
            value: [3,6],
            label_after: 'hours',
          },
          onset: {
            value: [30,60],
            label_after: 'minutes',
          },
          come_up: {
            value: [15,30],
            label_after: 'minutes',
          },
          peak: {
            value: [1.5,2.5],
            label_after: 'hours',
          },
          offset: {
            value: [1,1.5],
            label_after: 'hours',
          },
          after_effects: {
            value: [12,48],
            label_after: 'hours',
          }
        }
      },
    },
    // dosage_description: "",
    // duration_description: "",
    effects_introduction: "",
    effects: [],
    danger_risk: "",
    danger_risk_description: ""
  },
************/


};

export default {
  chems() {
    return chems || [];
  },
  chem(key) {
    return chems[key] || null;
  },
  dosage_keys() {
    return dosage_keys || [];
  },
  administration_methods() {
    return administration_methods || [];
  },
  duration_keys() {
    return duration_keys || [];
  },
};
