import api from "utils/api";
import { createAlert } from "../utils/constants";
import store from "../store";
const resource = "/api/journal";

export default {
  async getNotes(page, period = { month: "", year: "" }, text) {
    try {
      const { data } = await api.get(`${resource}/notes`, {
        params: { page, month: period.month, year: period.year, q: text },
      });

      return data;
    } catch (e) {
      return e.message;
    }
  },

  async createNote({ mood, content }) {
    try {
      const { data } = await api.post(`${resource}/notes`, {
        mood,
        content,
      });

      return data;
    } catch ({ response }) {
      return response.data;
    }
  },

  async updateNote({ mood, content, id }) {
    try {
      await api.put(`${resource}/notes/${id}`, {
        mood,
        content,
      });
    } catch (e) {
      return e.message;
    }
  },

  async deleteNote(id) {
    try {
      await api.delete(`${resource}/notes/${id}`);
    } catch (e) {
      return e.message;
    }
  },

  async checkIfIsEligibleToCreateExtensiveMood() {
    try {
      const { data } = await api.get("/api/extensive-journal/eligible");

      return data;
    } catch (e) {
      console.log(e.response.data.data);
    }
  },

  async createDailyMood({ answers, avgScore, mood, moodType }) {
    try {
      await api.post("/api/extensive-journal/moods", {
        answers,
        avg_score: avgScore,
        mood,
        mood_type: moodType,
      });
    } catch (e) {
      store.commit(
        "alerts/ADD_ALERT",
        createAlert(e.response.data.data, "ERROR")
      );
    }
  },
};
