import store from "../store";

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) next();
  else next({ name: "home" });
};

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) next();
  else next({ name: "login" });
};

export const ifAuthenticatedRedirectCheckedIn = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.currentEvent) {
      next({ name: "my-event", params: { uuid: store.getters.currentEvent } });
    } else {
      next();
    }
  } else {
    next({ name: "login" });
  }
};
