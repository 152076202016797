import {
  ifAuthenticated,
  ifAuthenticatedRedirectCheckedIn,
} from "./routeGuards";

export const eventsRoutes = [
  {
    path: "/events",
    name: "my-events",
    meta: { layout: "application-default", "gtm": "Event overview" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "event-overview" */ "@/views/events/Overview.vue"
      ),
    beforeEnter: ifAuthenticatedRedirectCheckedIn,
  },
  {
    path: "/events/:uuid",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "event-wrapper" */ "@/views/events/Wrapper.vue"
      ),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "",
        name: "my-event",
        meta: { layout: "application-default", "gtm": "Event overview" },
        component: () =>
          import(/* webpackChunkName: "events" */ "@/views/events/Index.vue"),
      },
      {
        path: "checklist",
        name: "my-event-checklist",
        meta: { layout: "application-default", "gtm": "Event checklist" },
        component: () =>
          import(
            /* webpackChunkName: "event-single-checklist" */ "@/views/events/Checklist.vue"
          ),
      },
      {
        path: "notebook",
        name: "my-event-notebook",
        meta: { layout: "application-default", "gtm": "Event notebook" },
        component: () =>
          import(
            /* webpackChunkName: "event-single-notebook" */ "@/views/events/Notebook.vue"
          ),
      },
      {
        path: "preparation-tool",
        name: "my-event-preparation-tool",
        meta: { layout: "application-default", "gtm": "Event preptool" },
        component: () =>
          import(
            /* webpackChunkName: "event-single-notebook" */ "@/views/events/PreparationTool.vue"
          ),
      },
    ],
  },
];
