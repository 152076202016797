// import { canUseWholeApp } from "./routeGuards";

export const infoRoutes = [
  {
    path: "/info",
    meta: { layout: "application-default" },
    component: () =>
    import(
      /* webpackChunkName: "info-overview" */ "@/views/info/Overview.vue"
    ),
    children: [
      {
        path: "/info",
        name: "info-index",
        meta: { layout: "application-default", gtm: "Info overview" },
        component: () =>
        import(/* webpackChunkName: "info-index" */ "@/views/info/Index.vue"),
      },
      {
        path: "drugcombos",
        name: "info-drugcombos",
        meta: { layout: "application-default", gtm: "Drugcombo tool" },
        component: () =>
        import(
          /* webpackChunkName: "info-drugcombos" */ "@/views/info/DrugCombos.vue"
        ),
      },
      {
        path: "chems",
        name: "info-chems",
        meta: { layout: "application-default", gtm: "Chem tool" },
        component: () =>
        import(/* webpackChunkName: "info-chems" */ "@/views/info/Chems.vue"),
      },
      {
        path: "emergency",
        component: () =>
        import(
          /* webpackChunkName: "emergency" */ "@/views/info/Emergency.vue"
        ),
        children: [
          {
            path: "",
            name: "info-emergency",
            meta: { layout: "application-default", gtm: "Emergency overview" },
            component: () =>
            import(
              /* webpackChunkName: "info-emergency" */ "@/views/info/emergency/Index.vue"
            ),
          },
          {
            path: ":id",
            name: "info-emergency-article",
            meta: { layout: "application-default", gtm: "Emergency article" },
            component: () =>
            import(
              /* webpackChunkName: "info-emergency-article" */ "@/views/info/emergency/Article.vue"
            ),
            props: true,
          },
        ],
      },
      {
        path: "assistance",
        name: "info-assistance",
        meta: { layout: "application-default" },
        component: () =>
        import(
          /* webpackChunkName: "info-assistance" */ "@/views/info/Assistance.vue"
        ),
      },

      {
        path: "articles",
        name: "article-overview",
        meta: { layout: "application-default" },
        component: () =>
          import(
            /* webpackChunkName: "article-overview" */ "@/views/articles/Overview.vue"
          ),
      },

      {
        path: "articles/chemsex",
        name: "article-chemsex",
        meta: { layout: "application-default" },
        component: () =>
          import(
            /* webpackChunkName: "article-chemsex" */ "@/views/articles/Chemsex.vue"
          ),
      },

      {
        path: "articles/:id",
        name: "article-single",
        meta: { layout: "application-default" },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "article-single" */ "@/views/articles/Single.vue"
          ),
      },

      {
        path: "quiz",
        name: "quiz-index",
        meta: { layout: "application-default" },
        component: () =>
          import(
            /* webpackChunkName: "quiz-index" */ "@/views/info/quiz/Index.vue"
          ),
      },
      {
        path: "quiz/:uuid",
        name: "quiz-detail",
        meta: { layout: "application-default" },
        component: () =>
          import(
            /* webpackChunkName: "quiz-detail" */ "@/views/info/quiz/Detail.vue"
          ),
      },
    ],
  },
];
