<template>
  <div class="flex mt-8 flex-col items-center space-y-4 text-center">
    <icon-loading class="w-4 h-4 text-brand-dark animate-spin" />
    <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style scoped></style>
