import journalRepo from "@/repositories/JournalRepository";
import { createAlert } from "@/utils/constants";

export default {
  namespaced: true,

  state: {
    notes: [],
    currentPage: 1,
    hasMorePages: false,
    noteToDelete: null
  },

  mutations: {
    SET_NOTES: (state, payload) => (state.notes = payload),
    UPDATE_NOTES: (state, payload) => [...state.notes, payload],
    SET_HAS_MORE_PAGES: (state, payload) => (state.hasMorePages = payload),
    SET_PAGE_NUMBER: (state, payload) => (state.currentPage = payload),
    SET_NOTE_TO_DELETE: (state, payload) => (state.noteToDelete = payload)
  },

  actions: {
    getNotes: async ({ commit, state }) => {
      commit("general/SET_LOADING", true, { root: true });
      const response = await journalRepo.getNotes(state.currentPage);

      if (response.success) {
        commit("SET_NOTES", response.data.data);
        commit("SET_PAGE_NUMBER", state.currentPage + 1);
      }
      commit("general/SET_LOADING", false, { root: true });
    },

    createNote: async ({ commit, dispatch }, payload) => {
      commit("general/SET_LOADING", true, { root: true });
      await journalRepo.createNote(payload);
      await dispatch("getNotes");
      commit(
        "alerts/ADD_ALERT",
        createAlert("successfully created note", "SUCCESS"),
        { root: true }
      );
      commit("general/SET_LOADING", false, { root: true });
    },

    updateNotes: async ({ commit, state }) => {
      const response = await journalRepo.getNotes(state.currentPage);
      commit("UPDATE_NOTES", response.data.data);
      commit("SET_PAGE_NUMBER", state.currentPage + 1);
    },

    updateNote: async ({ commit, dispatch }, payload) => {
      commit("general/SET_LOADING", true, { root: true });
      await journalRepo.updateNote(payload);
      await dispatch("getNotes");
      commit(
        "alerts/ADD_ALERT",
        createAlert("successfully updated note", "SUCCESS"),
        { root: true }
      );
      commit("general/SET_LOADING", false, { root: true });
    },

    deleteNote: async ({ commit, dispatch }, payload) => {
      commit("general/SET_LOADING", true, { root: true });
      await journalRepo.deleteNote(payload);
      await dispatch("getNotes");
      commit(
        "alerts/ADD_ALERT",
        createAlert("successfully deleted note", "SUCCESS"),
        { root: true }
      );
      commit("general/SET_LOADING", false, { root: true });
    }
  },

  getters: {
    notes: state => state.notes,
    noteToDelete: state => state.noteToDelete,
    hasMorePages: state => state.hasMorePages
  }
};
