<template>
  <component
    :is="layout || 'div'"
    class="layout-wrapper"
    :class="{
      'layout--default': !layout && !isCheckedIn,
      'layout--during': isCheckedIn || forceDarkMode,
      'layout--during-current':
        isCheckedIn && $route.params.uuid == currentEvent,
      'has-navbar': showNavBar,
    }"
    :data-route-param-event="$route.params.uuid"
  >
    <template v-if="!loading">
      <div class="layout-inner md:ml-32">
        <modals-wrapper />

        <alerts-wrapper class="fixed z-40 bottom-32 left-4" />

        <top-bar
          class="layout-topbar"
          v-if="showTopBar"
          :dark-mode="darkModeTopBar"
        />

        <slot class="layout-content" :class="{ 'has-navbar': showNavBar }" />
      </div>

      <nav-bar
        class="layout-navbar"
        v-if="showNavBar"
        :dark-mode="darkModeNavBar"
      />
    </template>

    <template v-else>
      <div
        class="flex flex-col items-center justify-center space-y-4 text-center layout-inner"
      >
        <icon-loading class="w-10 h-10 animate-spin text-brand-lightblue" />

        <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
      </div>
    </template>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import { modalTypes } from "@/utils/constants";
import NavBar from "@/layouts/partials/NavBar";
import TopBar from "@/layouts/partials/TopBar";

import IconLoading from "assets/icons/loading.svg";

import ModalsWrapper from "@/components/Modals/ModalsWrapper";
import AlertsWrapper from "@/components/Alerts/AlertsWrapper";

import RepositoryFactory from "repositories/RepositoryFactory";
const eventsRepository = RepositoryFactory.get("my-events");

export default {
  name: "DynamicLayout",
  components: {
    AlertsWrapper,
    ModalsWrapper,
    NavBar,
    TopBar,
    IconLoading,
  },
  props: {
    layout: {
      type: String,
      required: false,
      default: null,
    },
    showTopBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    showNavBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    forceDarkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    themeColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    if (!this.isAuthenticated) return;

    /* if (this.getProfile.modal_preferences.show_two_days_after_modal) {
      this.notifyUserTwoDaysAfterEvent();
    } */
    

    if (this.isCheckedIn) {
      if (!this.$cookies.get("hasAcceptedChecklistModal")) {
        this.checkIfEventIsToday();
      }

      if (
        !this.$cookies.get("hasAcceptedDontForgetCheckout") &&
        this.getProfile.modal_preferences.show_checkout_modal
      ) {
        this.$store.commit("TOGGLE_MODAL", {
          modalType: modalTypes.DONT_FORGET_CHECKOUT_MODAL,
          modalState: true,
        });
      }
    }
  },

  data() {
    return {
      notFilledInEvent: {},
    };
  },

  methods: {
    async checkIfEventIsToday() {
      const { data } = await eventsRepository.all();
      const hasEventsToday = data.events.some(
        (event) =>
          moment(event.date_from).format("DD/MM/YYYY") <=
            moment(Date.now()).format("DD/MM/YYYY") &&
          moment(event.date_until).format("DD/MM/YYYY") >=
            moment(Date.now()).format("DD/MM/YYYY")
      );

      if (hasEventsToday) {
        this.$store.commit("TOGGLE_CHECKLIST_MODAL", true);
      }
    },

    async notifyUserTwoDaysAfterEvent() {
      if (!this.getProfile.modal_preferences.show_two_days_after_modal && this.polling){
        clearInterval(this.polling);
      }
      if (!this.getProfile.modal_preferences.show_two_days_after_modal) return;

      const { data } = await eventsRepository.getEventsWithoutAfterMood();

      if (data.success) {
        const events = data.data.filter((event) => !event.log.length);

        if (events.length) {
          this.$nextTick(() => {
            this.notFilledInEvent = events[0];
            this.$store.commit("TOGGLE_MODAL", {
              modalType: modalTypes.TWO_DAYS_AFTER_EVENT_MODAL,
              modalState: true,
            });
          });
        }

        if (this.polling) return;
        /* this.polling = setInterval(() => {
          this.notifyUserTwoDaysAfterEvent();
        }, this.THIRTY_MIN_IN_MS); */

      }
    },
  },

  computed: {
    darkMode() {
      return this.forceDarkMode || this.isCheckedIn;
    },
    darkModeTopBar() {
      return this.forceDarkMode || this.isCheckedIn;
    },
    darkModeNavBar() {
      return this.isCheckedIn;
    },
    ...mapGetters([
      "isCheckedIn",
      "currentEvent",
      "isAuthenticated",
      "getProfile",
    ]),
  },

  metaInfo() {
    return {
      meta: [
        {
          vmid: "budd",
          name: "theme-color",
          content: this.themeColor || "#ffffff",
        },
      ],
    };
  },
};
</script>
