import axios from 'axios';
import qs from 'qs';

const AUTH_TOKEN_IDENTIFIER = 'authentication-token';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Content-Type': "application/json; charset=utf-8", // Toevoegen van de charset=utf-8
    }
});

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem([AUTH_TOKEN_IDENTIFIER])
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        config.paramsSerializer = (params) => {
            return qs.stringify(params, {arrayFormat: 'repeat'});
        };
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api
