import { ifAuthenticated } from "./routeGuards";

export const profileRoutes = [
  {
    path: "/profile",
    meta: { layout: "application-default" },
    component: () =>
      import(/* webpackChunkName: "settings-overview" */ "@/views/Profile.vue"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "/profile",
        name: "profile",
        meta: { layout: "application-default", gtm: "Profile" },
        component: () =>
          import(
            /* webpackChunkName: "settings-index" */ "@/views/settings/Index.vue"
          )
      },
      {
        name: "settings-avatar",
        path: "avatar",
        meta: { layout: "application-default", gtm: "Settings: avatar" },
        component: () =>
          import(
            /* webpackChunkName: "settings-avatar" */ "@/views/settings/Avatar.vue"
          )
      },
      {
        path: "checklist",
        name: "info-checklist",
        meta: { layout: "application-default", gtm: "Personal checklist" },
        component: () =>
          import(
            /* webpackChunkName: "settings-checklist" */ "@/views/settings/Checklist.vue"
          )
      },
      {
        path: "partners",
        name: "partners",
        meta: { layout: "application-default", gtm: "Personal checklist" },
        component: () =>
          import(
            /* webpackChunkName: "settings-partners" */ "@/views/settings/Partners.vue"
          )
      }
    ]
  }
];
