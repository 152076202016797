<template>
  <modal :show="checkListModalOpen" @close="handleCancel">
    <div class="p-6">
      <h5 class="font-semibold mb-12">
        {{ $t("modals.checklist.body") }}
      </h5>

      <t-button variant="primary" @click="acceptModal">{{
        $t("action.ok")
      }}</t-button>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import { mapGetters } from "vuex";

export default {
  name: "CheckList",
  components: {
    Modal
  },

  methods: {
    acceptModal() {
      this.$cookies.set("hasAcceptedChecklistModal", true, "30MIN");
      this.$store.commit("TOGGLE_CHECKLIST_MODAL", false);
    },

    handleCancel() {
      this.$store.commit("TOGGLE_CHECKLIST_MODAL", false);
    }
  },

  computed: {
    ...mapGetters(["checkListModalOpen"])
  }
};
</script>

<style scoped></style>
