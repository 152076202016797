<template>
  <nav class="navbar" :class="{ dark: !!darkMode }">
    <mobile-nav class="md:hidden"></mobile-nav>
    <desktop-nav class="hidden md:flex"></desktop-nav>
    <change-locale></change-locale>
  </nav>
</template>

<script>
import DesktopNav from "@/layouts/nav/Desktop";
import MobileNav from "@/layouts/nav/Mobile";
import ChangeLocale from "@/components/Modals/ChangeLocale";

export default {
  name: "navbar",

  components: {
    DesktopNav,
    MobileNav,
    ChangeLocale
  },

  props: {
    darkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
