export default {
  namespaced: true,

  state: {
    eventsView: "list",
    loading: false,
    appLoaded: false,
  },

  mutations: {
    SET_EVENTS_VIEW: (state, payload) => (state.eventsView = payload),
    SET_LOADING: (state, payload) => (state.loading = payload),
    SET_APP_LOADED: (state, payload) => (state.appLoaded = payload),
  },

  getters: {
    eventsView: (state) => state.eventsView,
    loading: (state) => state.loading,
    appLoaded: (state) => state.appLoaded,
  },
};
