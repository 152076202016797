<template>
  <div class="app">
    <component :is="layout" :loading="!csrfReady || loading || authLoading" :props="$props" class="min-h-screen mx-auto">
      <slot name="top" />

      <transition :name="transitionName" mode="out-in">
        <router-view :key="$route.path">
          <slot />
        </router-view>
      </transition>

      <slot name="bottom" />

      <portal-target name="bottom"></portal-target>

      <show-pwa-update v-if="showUpdateUI" @update-accepted="acceptUpdate" />
    </component>

    <portal to="topbar-right">
      <router-link
        v-if="isOnline"
        :to="{ name: 'profile' }"
        :class="{
          'border-2 border-brand-lightblue': isAuthenticated && !isProfileLoaded,
          'animate-pulse border-gray-500': authLoading,
        }"
        class="overflow-hidden rounded-full"
      >
        <icon-settings
          v-if="isAuthenticated"
          class="fill-current"
          :class="{
            'text-white': currentEvent,
            'text-brand-dark': !currentEvent,
          }"
        />
      </router-link>
      <div
        class="absolute flex items-center justify-center w-12 h-12 overflow-hidden bg-red-800 border-2 border-red-600 rounded-full shadow"
        v-else
      >
        <icon-close class="w-6 h-6 text-white" />
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import debounce from "lodash/debounce";
import { CSRF_REQUEST } from "actions/csrf";
import { USER_REQUEST, USER_FETCH_CACHED } from "actions/user";
import IconClose from "assets/icons/close.svg";
import IconSettings from "assets/icons/settings.svg";
import ShowPwaUpdate from "components/ShowPwaUpdate";
import RepositoryFactory from "repositories/RepositoryFactory";

const eventsRepo = RepositoryFactory.get("my-events");

export default {
  name: "App",
  components: {
    IconClose,
    IconSettings,
    ShowPwaUpdate,
  },
  props: {
    forceDarkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      transitionName: "slide-left",
      showUpdateUI: false,
      eventToday: false,
    };
  },
  mounted: debounce(function () {
    this.$nextTick(() => {
      this.loading = false;
    });

    this.$i18n.locale = this.getLanguage;
    this.$moment.locale(this.getLanguage);
  }, 1000),

  methods: {
    async acceptUpdate() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },

    async checkIfEventIsToday() {
      const { data } = await eventsRepo.all();
      this.eventToday = data.events.some(
        (event) => this.$moment(event.date_from).format("DD/MM/YYYY") === this.$moment(Date.now()).format("DD/MM/YYYY")
      );
    },
  },
  beforeCreate: function () {
    this.$store.dispatch(USER_FETCH_CACHED);
    this.$store.dispatch(CSRF_REQUEST);
  },
  created: function () {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }

    if (this.isAuthenticated) {
      this.$gtm && this.$gtm.enable(!!this.getProfile?.enable_analytics);
      this.$store.dispatch(USER_REQUEST).then(() => {
        if (this.currentEvent) {
          this.$router.push({
            name: "my-event",
            params: { uuid: this.currentEvent },
          });
        } else {
          this.checkIfEventIsToday();
        }
        if (this.getLanguage) {
          this.$i18n.locale = this.getLanguage;
          this.$moment.locale(this.getLanguage);
        }
      });
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
    ...mapGetters([
      "isAuthenticated",
      "isProfileLoaded",
      "getUsername",
      "getLanguage",
      "getAvatar",
      "isCheckedIn",
      "currentEvent",
      "csrfReady",
      "getProfile",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
    }),
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth === fromDepth || toDepth === 1 ? "fade" : toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_TITLE}`,
      meta: [{ name: "theme-color", content: "#ff0000" }],
    };
  },
};
</script>
