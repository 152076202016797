<template>
  <div class="modals__wrapper">
    <daily-mood />
    <checklist-modal />
    <delete-note />
    <checkout />
    <create-event />
  </div>
</template>

<script>
import ChecklistModal from "./CheckList";
import DeleteNote from "./DeleteNote";
import DailyMood from "./DailyMood";
import Checkout from "./Checkout";
import CreateEvent from "./CreateEvent";

export default {
  components: {
    Checkout,
    DailyMood,
    DeleteNote,
    ChecklistModal,
    CreateEvent,
  },
  name: "ModalsWrapper",
};
</script>

<style scoped>
.modals__wrapper {
  z-index: 999;
}
</style>
