import api from "utils/api";
const resource = "/api/articles";

export default {
  all(language) {
    return api.get(`${resource}`, { params: { language } });
  },
  get(id) {
    return api.get(`${resource}/${id}`);
  },
  bookmarkArticle(id, isFavorited) {
    return api.put(`${resource}/favorites/${id}`, { favorite: isFavorited });
  },
  categories() {
    return api.get(`${resource}/categories`);
  },
};
