import { uuid4 } from "@capacitor/core/dist/esm/util";

export const extensiveJournalItems = [
  {
    langKey1: "very_tired",
    langKey2: "very_awake",
    min: 0,
    max: 6,
  },
  {
    langKey1: "very_unsatisfied",
    langKey2: "very_satisfied",
    min: 0,
    max: 6,
  },
  {
    langKey1: "very_agitated",
    langKey2: "very_calm",
    min: 0,
    max: 6,
  },
  {
    langKey1: "very_full_of_energy",
    langKey2: "very_empty_energy",
    min: -6,
    max: 0,
  },
  {
    langKey1: "very_well",
    langKey2: "very_unwell",
    min: -6,
    max: 0,
  },
  {
    langKey1: "very_relaxed",
    langKey2: "very_tensed",
    min: -6,
    max: 0,
  },
];

export const createAlert = (text, alertType) => ({
  id: uuid4(),
  text,
  type: alertType,
});

export const preptoolQuestions = {
  practical: [
    {
      question_translation_key: "preptool_question_one",
      info_translation_key: "preptool_info_one",
      question_after_translation_key: "preptool_after_question_one",
      intro: null,
      answer_type: "text",
    },
    {
      question_translation_key: "preptool_question_two",
      info_translation_key: "preptool_info_two",
      question_after_translation_key: "preptool_after_question_two",
      intro: "preptool_intro_two",
      answer_type: "calendar"
      // Note: custom logic at preptool item component
    },
    {
      question_translation_key: "preptool_question_three",
      info_translation_key: "preptool_info_three",
      question_after_translation_key: "preptool_after_question_three",
      intro: "preptool_intro_three",
      answer_type: "select",
      answers: [
        {
          value: undefined,
          text: "choose_answer",
        },
        {
          value: "public_transport",
          text: "public_transport",
        },
        {
          value: "bicycle",
          text: "bicycle",
        },
        {
          value: "taxi",
          text: "taxi",
        },
        {
          value: "stay_night",
          text: "stay_night",
        },
        {
          value: "ride_along",
          text: "ride_along",
        },
        {
          value: "does_not_apply",
          text: "does_not_apply",
        },
      ],
    },
  ],
  chems: [
    {
      question_translation_key: "preptool_question_four",
      info_translation_key: "preptool_info_four",
      question_after_translation_key: "preptool_after_question_eight",
      intro: null,
      answer_type: "checkbox",
      answers: [
        "alcohol",
        "ghb",
        "cocaine",
        "amphetamine",
        "methamphetamine",
        "fourmmc",
        "threemmc",
        "mdma",
        "ketamine",
        "weed",
        "poppers",
      ],
    },
    {
      question_translation_key: "preptool_question_five",
      question_after_translation_key: "preptool_after_question_five",
      info_translation_key: null,
      intro: "preptool_intro_five",
      answer_type: "select",
      answers: [
        {
          label: "niets",
          text: "niets",
          value: "0",
        },
        {
          label: "Een beetje",
          text: "eenbeetje",
          value: "1",
        },
        {
          label: "Voldoende",
          text: "voldoende",
          value: "2",
        },
        {
          label: "Meer dan voldoende",
          text: "meerdanvoldoende",
          value: "3",
        },
        {
          label: "Ik weet ALLES!",
          text: "ikweetalles",
          value: "4",
        },
      ],
    },
    {
      question_translation_key: "preptool_question_six",
      question_after_translation_key: "preptool_after_question_six",
      info_translation_key: "preptool_info_six",
      intro: "preptool_intro_six",
      answer_type: "radio",
    },
  ],
  sex: [
    {
      question_translation_key: "preptool_question_seven",
      question_after_translation_key: "preptool_after_question_seven",
      info_translation_key: "preptool_info_seven",
      intro: null,
      answer_type: "radio",
    },
    {
      question_translation_key: "preptool_question_eight",
      question_after_translation_key: "preptool_after_question_eight",
      info_translation_key: "preptool_info_eight",
      intro: null,
      answer_type: "text",
    },
    {
      question_translation_key: "preptool_question_nine",
      question_after_translation_key: undefined,
      info_translation_key: null,
      intro: null,
      answer_type: "radio",
    },
  ],
};

export const infoItems = [
  {
    headerText: "info.title--cheminfo",
    items: [
      {
        routerLinkName: "info-chems",
        listItemStartComponent: "icon-chem-info",
        listItemText: "navigation.chems",
        iconWrapperClass: "bg-yellow-300 ring-yellow-300 text-yellow-900",
        iconColorClass: "text-yellow-900",
      },
      {
        routerLinkName: "info-drugcombos",
        listItemStartComponent: "icon-drugcombo",
        listItemText: "navigation.drugcombos",
        iconWrapperClass: "bg-yellow-300 ring-yellow-300 text-yellow-900",
        iconColorClass: "text-yellow-900",
      },
    ],
  },
  {
    headerText: "info.title--help",
    items: [
      {
        routerLinkName: "info-assistance",
        listItemStartComponent: "icon-assistance",
        listItemText: "navigation.assistance",
        iconWrapperClass: "bg-red-300 ring-red-300 text-red-900",
        iconColorClass: "text-red-900",
      },
      {
        routerLinkName: "info-emergency",
        listItemStartComponent: "icon-emergency-help",
        listItemText: "navigation.emergency",
        iconWrapperClass: "bg-red-300 ring-red-300 text-red-900",
        iconColorClass: "text-red-900",
      },
    ],
  },
  {
    headerText: "info.title--general",
    items: [
      {
        routerLinkName: "article-overview",
        listItemStartComponent: "icon-articles-book-lines",
        listItemText: "navigation.articles",
        iconWrapperClass: "bg-blue-300 ring-blue-300 text-blue-900",
        iconColorClass: "text-blue-900",
      },
      {
        routerLinkName: "article-chemsex",
        listItemStartComponent: "icon-people",
        listItemText: "navigation.chemsex_stories",
        iconWrapperClass: "bg-green-300 ring-green-300 text-green-900",
        iconColorClass: "text-green-900",
      },
    ],
  },
  // disabled during effectivity study
  /* {
    headerText: "Want to take part in quizzes?",
    items: [
      {
        routerLinkName: "quiz-index",
        listItemStartComponent: "icon-quiz",
        listItemText: "Quizzes",
      },
    ],
  }, */
];

export const modalTypes = {
  CHECKLIST_MODAL: "checkListModalOpen",
  DELETE_NOTE_MODAL: "deleteNoteModalOpen",
  DAILY_MOOD_MODAL: "dailyMoodModalOpen",
  DONT_FORGET_CHECKOUT_MODAL: "dontForgetCheckoutModalOpen",
  CREATE_EVENT_MODAL: "createEventModalOpen",
  TWO_DAYS_AFTER_EVENT_MODAL: "twoDaysAfterEventModalOpen",
  TUTORIAL_MODAL: "tutorialModalOpen",
  CHANGE_LOCALE_MODAL: "changeLocaleModalOpen",
};
