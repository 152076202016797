import { ifAuthenticated } from "./routeGuards";

export const assessmentRoutes = [
  {
    path: "/assessment",
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* WebpackChunkName: 'assessment-overview' */ "@/views/assessment/Overview.vue"
      ),
    children: [
      {
        path: "/assessment",
        meta: { layout: "application-default" },
        name: "assessment",
        component: () =>
          import(
            /* WebpackChunkName: 'assessment-index' */ "@/views/assessment/Index.vue"
          )
      }
    ]
  }
];
