<template>
  <div class="layout-wrapper">
    <slot class="layout-content" />
    <nav-bar class="layout-navbar" v-if="showNavbar" />
  </div>
</template>

<script>
import NavBar from '@/layouts/partials/NavBar'
export default {
  name: 'DefaultLayout',
  components: {
    NavBar
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: false
    }
  }
}
</script>