import { CSRF_REQUEST } from 'actions/csrf';
import api from 'utils/api'

const state = {
    hasLoadedOnce: false
}

const mutations = {
    [CSRF_REQUEST]: (state) => {
        state.hasLoadedOnce = true
    }
}

const actions = {
    [CSRF_REQUEST]: ({commit/* , dispatch */}) => {
        return new Promise(() => {
            api.get('sanctum/csrf-cookie').then(()=>{
                commit(CSRF_REQUEST);
            });
        })
    },
}

const getters = {
  csrfReady: state => !!state.hasLoadedOnce,
};

const authModule = {
    state,
    mutations,
    getters,
    actions
}

export default authModule