import api from "utils/api";
const resource = "/api/my/events";

export default {
  all(params) {
    return api.get(`${resource}`, { params });
  },

  date(date) {
    return api.get(`${resource}`, { params: { filter: { date } } });
  },

  filter(filter) {
    return api.get(`${resource}`, { params: { filter: filter } });
  },

  today() {
    const date_today = new Date().toISOString().split("T")[0];
    return api.get(`${resource}`, { params: { filter: { date: date_today, until: date_today } } });
  },

  past() {
    const date_today = new Date().toISOString().split("T")[0];
    return api.get(`${resource}`, { params: { filter: { until: date_today } } });
  },

  pastMoodlessAfterTwoDays() 
  {
	  
    const	now = new Date();
    const 	date_2daysago = new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]; // 2 dagen
    const 	date_1weekago = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]; // 1 week
    return 	api.get
			(
				`${resource}`, 
				{ 
					params: 
					{ 
						filter: 
						{ 
							date: date_1weekago, 
							until: date_2daysago, 
							after_mood_missing: true 
						} 
					} 
				}
			);
  },



  async getEventsWithoutAfterMood() {
    const { data } = await api.get(`${resource}/not-filled-mood`);
    return data;
  },


  future() {
    return api.get(`${resource}/future`);
  },

  get(uuid) {
    return api.get(`${resource}/${uuid}`);
  },

  create(data) {
    return api.post(`${resource}`, data);
  },

  update(uuid, data) {
    return api.put(`${resource}/${uuid}`, data);
  },

  delete(uuid) {
    return api.delete(`${resource}/${uuid}`);
  },

  checkIn(uuid, { mood = "neutral", note = "" }) {
    return api.post(`${resource}/${uuid}/log`, {
      type: "checkin",
      mood,
      note,
    });
  },

  checkOut(uuid, { mood = "neutral", note = "" }) {
    return api.post(`${resource}/${uuid}/log`, {
      type: "checkout",
      mood,
      note,
    });
  },

  checkAfter(uuid, data) 
  {
    const { mood, note, preptoolAnswers } = data;

    if (!preptoolAnswers) {
      return api.post(`${resource}/${uuid}/log`, {
        type: "after",
        mood,
        note,
      });
    }
    return api.post(`${resource}/${uuid}/log`, {
      type: "after",
      mood,
      note,
      answers: JSON.stringify(preptoolAnswers),
    });
  },

  addNote(uuid, note, mood) {
    return api.post(`${resource}/${uuid}/log`, { type: "note", note, mood });
  },

  /* todos(event_uuid) {
        return api.get(`${resource}/${event_uuid}/todos`);
    }, */

  addTodo(event_uuid, description, personal) {
    var personal_bool = personal == true ? 1 : 0;
    return api.post(`${resource}/${event_uuid}/todos`, {
      description: description,
      personal: personal_bool,
    });
  },

  bulkAddTodos(event_uuid, items) {
    return api.post(`${resource}/${event_uuid}/todos/bulk`, {
      items,
    });
  },

  updateTodo(event_uuid, todo_uuid, description) {
    return api.put(`${resource}/${event_uuid}/todos/${todo_uuid}`, {
      description: description,
    });
  },

  deleteTodo(event_uuid, todo_uuid) {
    return api.delete(`${resource}/${event_uuid}/todos/${todo_uuid}`);
  },

  finishTodo(event_uuid, todo_uuid) {
    return api.post(`${resource}/${event_uuid}/todos/${todo_uuid}/finish`);
  },

  unfinishTodo(event_uuid, todo_uuid) {
    return api.post(`${resource}/${event_uuid}/todos/${todo_uuid}/unfinish`);
  },

  syncPersonalTodosToEvent(event_uuid) {
    return api.post(`${resource}/${event_uuid}/todos/sync-in-personal-todos`);
  },

  syncExampleTodosToChecklist(event_uuid) {
    return api.post(`${resource}/${event_uuid}/todos/sync-in-example-todos`);
  },

  assignTip(event_uuid, tip_uuid, ignored) {
    var ignored_bool = ignored == true ? 1 : 0;
    return api.post(`${resource}/${event_uuid}/tip`, {
      tip_uuid,
      ignored: ignored_bool,
    });
  },

  setTipUsefulness(event_uuid, assignment_id, usefulness) {
    var usefulness_bool = usefulness == true ? 1 : 0;
    return api.put(`${resource}/${event_uuid}/tip/${assignment_id}`, {
      used: usefulness_bool,
    });
  },

  async getHolidays() {
    const { data } = await api.get(`/api/holidays`);
    return data;
  },

  async toggleFavoriteTodo(eventUuid, todoUuid) {
    return api.put(`${resource}/${eventUuid}/todos/${todoUuid}/favorite`);
  },

  async getPreparationToolDataForEvent(eventUuid) {
    const { data } = await api.get(`${resource}/${eventUuid}/preparation-tool`);
    return data;
  },

  async updatePreparationToolDataForEvent(eventUuid, preparationToolAnswers) {
    await api.post(`${resource}/${eventUuid}/preparation-tool`, {
      preparationToolAnswers: JSON.stringify(preparationToolAnswers),
    });
  },
};
