import api from "utils/api";
const resource = "/api/statistics";

export default {
  async get(period) {
    const params = {
      period: {
        month: period?.month !== null ? parseInt(period.month) + 1 : undefined,
        year: parseInt(period.year)
      }
    };

    return api.get(resource, { params });
  },
};
