import quizzesRepository from "../../repositories/quizzesRepository";
import { getUniqueValues } from "../../utils/helpers";

export default {
  namespaced: true,

  state: {
    quizzes: [],
    categories: [],
    quiz: {},
    totalQuestions: 0,
    rightAnswers: 0
  },

  mutations: {
    SET_CATEGORIES: (state, payload) => (state.categories = payload),
    SET_QUIZZES: (state, payload) => (state.quizzes = payload),
    SET_QUIZ: (state, payload) => (state.quiz = payload),
    SET_QUESTIONS_TOTAL: (state, payload) => (state.totalQuestions = payload),
    INCREMENT_RIGHT_ANSWERS: state => (state.rightAnswers += 1),
    RESET_RIGHT_ANSWERS_COUNT: state => (state.rightAnswers = 0)
  },

  actions: {
    async initializeQuizzes({ commit }) {
      const response = await quizzesRepository.all();
      const categories = getUniqueValues(response.data, "category");
      commit("SET_CATEGORIES", categories);

      let quizObject = {};
      categories.forEach(cat => {
        if (response.data.filter(quiz => quiz.category === cat).length) {
          quizObject = {
            ...quizObject,
            [cat]: response.data.filter(quiz => quiz.category === cat)
          };
        }
      });

      commit("SET_QUIZZES", quizObject);
    },

    async getQuizDetail({ commit }, payload) {
      const response = await quizzesRepository.detail(payload);

      commit("SET_QUESTIONS_TOTAL", response.data.questions.length);
      commit("SET_QUIZ", response.data);
    },

    async finishQuiz({ state }) {
      await quizzesRepository.finishQuiz(state.quiz.uuid, {
        quizIsFilledIn: true,
        rightAnswers: state.rightAnswers
      });
    }
  },
  getters: {
    quizzes: state => state.quizzes,
    quiz: state => state.quiz,
    categories: state => state.categories,
    totalQuestions: state => state.totalQuestions,
    rightAnswers: state => state.rightAnswers
  }
};
