<template>
  <icon-logo v-if="type === 'dark'" />
  <img
    src="@/assets/logo/logo-stacked.png"
    alt="Budd"
    v-else-if="type === 'dark-stacked'"
  />
  <icon-logo-white v-else />
</template>

<script>
import IconLogo from "@/assets/logo/logo.svg";
import IconLogoWhite from "@/assets/logo/logo_white.svg";

export default {
  components: {
    IconLogo,
    IconLogoWhite
  },
  props: {
    type: {
      type: String,
      default: "white"
    }
  }
};
</script>
