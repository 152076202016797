import { ifAuthenticated } from "./routeGuards";

export const statisticsRoutes = [
  {
    path: "/stats",
    name: "statistics-index",
    meta: { layout: "application-default", "gtm": "Statistics overview" },
    component: () =>
      import(
        /* webpackChunkName: "statistics-index" */ "@/views/stats/Index.vue"
      ),
    beforeEnter: ifAuthenticated
  }
];
