<template>
  <div
    class="relative mx-auto mb-16 flex w-full max-w-screen-sm flex-col px-6 md:mt-10 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Wrapper.vue",
};
</script>

<style scoped></style>
