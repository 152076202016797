import Vue from "vue";
import Vuex from "vuex";
import csrf from "./modules/csrf";
import auth from "./modules/auth";
import user from "./modules/user";
import general from "./modules/general";
import articles from "./modules/articles";
import alerts from "./modules/alerts";
import info from "./modules/info";
import modals from "./modules/modals";
import assessment from "./modules/assessment";
import quizzes from "./modules/quizzes";
import journal from "./modules/journal";
import study from "./modules/study";
import notifications from "./modules/notifications";
import preptool from "./modules/preptool";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    csrf,
    auth,
    user,
    articles,
    alerts,
    info,
    general,
    modals,
    assessment,
    quizzes,
    journal,
    study,
    preptool,
    notifications,
  },
  strict: debug,
});
