import RepositoryFactory from "@/repositories/RepositoryFactory";
const eventsRepository = RepositoryFactory.get("my-events");

const state = {
  notifications: {
    'checkout': {
      active: false,
      data: null,
      dismissed: false,
    },
    'checklist': {
      active: false,
      data: null,
      dismissed: false,
    },
    'after_two_days': {
      active: false,
      data: null,
      dismissed: false,
    }
  },
};

const actions = {
  checkNotifications: ({ dispatch }) => {
    dispatch('checkNotification__checklist');
    dispatch('checkNotification__checkout');
    dispatch('checkNotification__after_two_days');
  },

  checkDismissed: ({ getters }, key) => {
    if (getters.getNotifications[key].dismissed) return true;
    return false;
  },

  dismissAllNotifications: ({ state, commit }) => {
    Object.keys(state.notifications).forEach(key => commit('setNotificationInactive', [key, true]));
  },

  dismissNotification: ({ commit }, key) => {
    commit('setNotificationInactive', [key, true]);
  },

  checkNotification__checklist: ({  /* state, */ commit, getters }) => {
    if (getters.getNotifications['checklist'].dismissed) return;

    eventsRepository.today().then(({ data }) => {
      console.log('checkNotification__checklist', data);
      if (data.events.length >= 1) {
        commit('setNotificationActive', ['checklist', { type: 'event', 'identifier': data.events[0].uuid }]);
      } else {
        commit('setNotificationInactive', ['checklist', null]);
      }
    });
  },

  checkNotification__checkout: ({  /* state, */ rootGetters, commit, getters }) => {
    if (getters.getNotifications['checkout'].dismissed) return;
    console.log('checkNotification__checkout', rootGetters.currentEvent);

    if (rootGetters.currentEvent) {
      commit('setNotificationActive', ['checkout', { type: 'event', identifier: rootGetters.currentEvent }]);
    } else {
      commit('setNotificationInactive', ['checkout', null]);
    }
  },

  checkNotification__after_two_days: async ({ /* state, */ commit, getters }) => {
    if (getters.getNotifications['after_two_days'].dismissed) return;

    await eventsRepository.getEventsWithoutAfterMood().then(({ data }) => {
      console.log('checkNotification__after_two_days', data);
      if (data.length >= 1) {
        commit('setNotificationActive', ['after_two_days', { type: 'event', 'identifier': data[0].uuid }]);
      } else {
        commit('setNotificationInactive', ['after_two_days', null]);
      }
    });
  }
};


const mutations = {
  'setNotificationInactive': (state, payload) => {
    const [notification_key, dismissed] = payload;
    console.log('setNotificationInactive', payload, notification_key, dismissed);
    if (!state.notifications[notification_key]) {
      return;
    }
    state.notifications[notification_key].active = false;
    state.notifications[notification_key].data = null;
    if (dismissed) {
      state.notifications[notification_key].dismissed = dismissed;
    }
  },
  'setNotificationActive': (state, payload) => {
    const [notification_key, data] = payload;
    console.log('setNotificationInactive', payload, notification_key, data);
    if (!state.notifications[notification_key]) {
      return;
    }
    state.notifications[notification_key].active = true;
    state.notifications[notification_key].data = data;
  }
};

const getters = {
  getNotifications: (state) => state.notifications,
  hasActiveNotifications: (state) => !!Object.values(state.notifications).filter((notification) => !!notification.active).length,
  countActiveNotifications: (state) => Object.values(state.notifications).filter((notification) => !!notification.active).length,
};


export default {
  state,
  getters,
  actions,
  mutations
};
