import ArticleRepository from "./ArticleRepository";
import TipRepository from "./TipRepository";
import MyEventRepository from "./MyEventRepository";
import TodoRepository from "./TodoRepository";
import UserRepository from "./UserRepository";
import DrugRepository from "./DrugRepository";
import EmergencyRepository from "./EmergencyRepository";
import InfoRepository from "./InfoRepository";
import AssessmentRepository from "./AssessmentRepository";
import JournalRepository from "./JournalRepository";
import ChemRepository from "./ChemRepository";
import StatisticRepository from "./StatisticRepository";

const repositories = {
  tips: TipRepository,
  articles: ArticleRepository,
  "my-events": MyEventRepository,
  todos: TodoRepository,
  user: UserRepository,
  journal: JournalRepository,
  drug: DrugRepository,
  emergency: EmergencyRepository,
  info: InfoRepository,
  assessment: AssessmentRepository,
  chem: ChemRepository,
  stats: StatisticRepository
};
export default {
  get: name => repositories[name]
};
