export default {
  namespaced: true,

  state: {
    alerts: []
  },

  mutations: {
    ADD_ALERT(state, payload) {
      state.alerts.push(payload);
      setTimeout(() => {
        this.commit("alerts/REMOVE_ALERT", payload);
      }, 5000);
    },
    REMOVE_ALERT: (state, payload) =>
      (state.alerts = state.alerts.filter(alert => alert.id !== payload.id))
  },

  getters: {
    alerts: state => state.alerts
  }
};
