import { ifAuthenticated } from "./routeGuards";

export const journalRoutes = [
  {
    path: "/journal",
    name: "journal-index",
    meta: { layout: "application-default", "gtm": "Journal overview" },
    component: () =>
      import(
        /* webpackChunkName: "journal-index" */ "@/views/journal/Index.vue"
      ),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/extensive-journal",
    name: "journal-extensive-index",
    meta: { layout: "application-default", "gtm": "Extensive journal" },
    component: () =>
      import(
        /* webpackChunkName: "journal-index" */ "@/views/journal/Extensive.vue"
      ),
    beforeEnter: ifAuthenticated
  }
];
