<template>
  <div class="w-full">
    <transition-group tag="div" name="alert-list">
      <alert v-for="alert in alerts" :alert="alert" :key="alert.id" />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "./Alert.vue";

export default {
  name: "AlertsWrapper",

  components: {
    Alert
  },

  computed: {
    ...mapGetters({
      alerts: "alerts/alerts"
    })
  }
};
</script>
