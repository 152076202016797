<template>
  <modal :show="changeLocaleModalOpen" @close="closeModal">
    <div class="p-6">
      <h5 class="font-semibold mb-3 text-center">{{ $t("modals.change_locale.title") }}</h5>
      <div class="space-y-4">
        <locale-switcher class="w-full"></locale-switcher>
        <t-button variant="primary" @click="closeModal">
          {{ $t("action.ok") }}
        </t-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "components/Modal";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { mapGetters } from "vuex";

export default {
  name: "ChangeLocale",

  components: {
    Modal,
    LocaleSwitcher
  },

  methods: {
    closeModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: "changeLocaleModalOpen",
        modalState: false,
      });
    }
  },

  computed: {
    ...mapGetters(["changeLocaleModalOpen"]),
  },
};
</script>

<style scoped>
</style>
