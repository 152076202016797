export default {
  namespaced: true,

  state: () => ({
    answers: {},
    loadedAnswers: null
  }),

  mutations: {
    ADD_ANSWER: (state, payload) => {
      state.answers = {
        ...state.answers,
        [payload.question]: {
          answer: payload.answer,
          question_after: payload.question_after
        }
      };
    },

    ADD_ANSWERS: (state, payload) => {
      state.loadedAnswers = payload;
    },

    REMOVE_ANSWERS: state => {
      state.answers = {};
    }
  },

  getters: {
    answers: state => state.answers,
    loadedAnswers: state => state.loadedAnswers
  }
};
