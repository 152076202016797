<template>
  <select
    v-model="currentLocale"
    class="px-2 leading-6 appearance-none form-select focus:outline-none w-44 rounded-3xl text-brand-dark"
  >
    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
      {{ short ? lang.toUpperCase() : $t(`language.${lang}`, lang) }}
    </option>
  </select>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import { mapGetters } from "vuex";
const UserRepository = Repository.get("user");

export default {
  name: "locale-switcher",

  props: {
    short: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { langs: ["nl", "en", "fr"] };
  },

  methods: {
    changeLocale(language) {
      this.$i18n.locale = language;
      this.$moment.locale(language);

      if (this.isAuthenticated) {
        UserRepository.updateLanguage(language);
      }
    },
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
    currentLocale: {
      get: function () {
        return this.$i18n.locale || this.$i18n.fallbackLocale;
      },
      // setter
      set: function (language) {
        this.changeLocale(language);
      }
      
    }
  },
};
</script>
