import RepositoryFactory from "repositories/RepositoryFactory";

const journalRepository = RepositoryFactory.get("journal");

export default {
  namespaced: true,

  state: {
    canSubmitExtensiveItem: false,
    canSubmitDailyMood: false
  },

  mutations: {
    SET_CAN_SUBMIT_EXTENSIVE_MOOD: (state, payload) =>
      (state.canSubmitExtensiveItem = payload),
    SET_CAN_SUBMIT_DAILY_MOOD: (state, payload) =>
      (state.canSubmitDailyMood = payload)
  },

  actions: {
    async checkIfIsEligibleToCreateExtensiveMood({ commit }) {
      const data = await journalRepository.checkIfIsEligibleToCreateExtensiveMood();
      if (data?.success) {
        commit("SET_CAN_SUBMIT_EXTENSIVE_MOOD", data.data.extensive_journal);
        commit("SET_CAN_SUBMIT_DAILY_MOOD", data.data.normal_journal);
      }
    }
  },

  getters: {
    canSubmitExtensiveItem: state => state.canSubmitExtensiveItem,
    canSubmitDailyMood: state => state.canSubmitDailyMood
  }
};
