import ArticleRepository from "@/repositories/ArticleRepository";
import { addMinutesToDate, getUniqueValues } from "@/utils/helpers";

export default {
  state: {
    searchText: "",
    assistanceArticles: [],
    countries: [],
    provinces: [],
    articles: {
      refetchInterval: null,
      items: [],
    },

  },

  mutations: {
    SET_ASSISTANCE_ARTICLES: (state, payload) => {
      state.assistanceArticles = payload;
    },

    SET_COUNTRIES: (state, payload) => {
      state.countries = payload;
    },

    SET_PROVINCES: (state, payload) => {
      state.provinces = payload;
    },

    SET_SEARCH_TEXT: (state, payload) => (state.searchText = payload),

    SET_RANDOM_ARTICLES: (state, payload) => {
      state.articles = {
        items: payload.slice(0, 4),
        refetchInterval: addMinutesToDate(5),
      };
    },
  },

  actions: {
    getAssistanceArticles: ({ commit }, payload) => {
      const provinces = getUniqueValues(payload, "province");
      const countries = getUniqueValues(payload, "country");
      commit("SET_ASSISTANCE_ARTICLES", payload);
      commit("SET_PROVINCES", provinces);
      commit("SET_COUNTRIES", countries);
    },

    setSpecificArticles: ({ commit }, payload) => {
      const provinces = getUniqueValues(payload, "province");
      commit("SET_ASSISTANCE_ARTICLES", payload);
      commit("SET_PROVINCES", provinces);
    },

    async fetchRandomArticles({ commit }) {
      const response = await ArticleRepository.all();
      commit("SET_RANDOM_ARTICLES", response.data.data);
    },
  },

  getters: {
    assistanceArticles: (state) =>
      state.assistanceArticles.filter((assistanceArticle) =>
        assistanceArticle.title
          .toLowerCase()
          .includes(state.searchText.toLowerCase())
      ),
    provinces: (state) => state.provinces,
    countries: (state) => state.countries,
    searchText: (state) => state.searchText,
    randomArticles: (state) => state.articles,
  },
};
