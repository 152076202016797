import api from "utils/api";

const filterArticles = (articles, categories) => {
  let articlesObject = {};
  categories.forEach((cat) => {
    if (articles.filter((art) => art.category === cat).length) {
      articlesObject = {
        ...articlesObject,
        [cat]: articles.filter((art) => art.category === cat),
      };
    }
  });

  return articlesObject;
};

const getUniqueCategories = (responseCategories) =>
  Array.from(new Set(responseCategories.sort((a, b) => a.category_order - b.category_order).map((article) => article.category)));

export default {
  state: {
    articles: [],
    categories: [],
    chemsexStories: [],
  },

  mutations: {
    SET_ARTICLES: (state, payload) => {
      state.articles = payload;
    },

    SET_CHEMSEX_STORIES: (state, payload) => (state.chemsexStories = payload),

    SET_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
  },

  actions: {
    async getArticles({ commit }, { searchText, language }) {
      try {
        const { data } = await api.get("/api/articles", {
          params: { keyword: searchText, language },
        });

        const categories = getUniqueCategories(data.data);
        const articles = filterArticles(data.data, categories);

        commit("SET_CATEGORIES", categories);
        commit("SET_ARTICLES", articles);
      } catch (e) {
        console.error(e.message);
      }
    },

    async getFavorites({ commit }) {
      try {
        const { data } = await api.get("/api/articles/favorites");

        const categories = getUniqueCategories(data.data);
        const articles = filterArticles(data.data, categories);

        commit("SET_ARTICLES", articles);
        commit("SET_CATEGORIES", categories);
      } catch (e) {
        console.error(e.message);
      }
    },

    async getChemsexStories({ commit }, { searchText, language }) {
      try {
        const { data } = await api.get("/api/articles/chemsex", {
          params: { keyword: searchText, language },
        });

        if (data.success) {
          commit("SET_CHEMSEX_STORIES", data.data);
        }
      } catch (e) {
        console.log(e.response);
      }
    },
  },

  getters: {
    articles: (state) => state.articles,
    categories: (state) => state.categories,
    chemsexStories: (state) => state.chemsexStories,
    nextUrl: (state) => state.meta?.next,
    prevUrl: (state) => state.meta?.prev,
  },
};
