import api from "utils/api";
const resource = "/api/assessment";

export default {
  async all() {
    try {
      const { data } = await api.get(`${resource}/questions`);
      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async userCanParticipate() {
    try {
      const { data } = await api.get(`${resource}/is-eligible`);
      return data;
    } catch (e) {
      return e.message;
    }
  },

  async create({ result, completed_quiz }) {
    try {
      const { data } = await api.post(`${resource}/results`, {
        result,
        completed_quiz,
      });

      return data;
    } catch (e) {
      return e.message;
    }
  },
};
