import Vue from "vue";

import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_FETCH_CACHED,
  UPDATE_BUDDIES, UPDATE_MODAL_PREFERENCES, UPDATE_ANALYTICS
} from "actions/user";
import { USER_CACHE_IDENTIFIER, USERID_COOKIE_IDENTIFIER } from "cache/user";
import { AUTH_LOGOUT } from "actions/auth";

import api from "utils/api";
import UserRepository from "@/repositories/UserRepository";

const state = {
  status: "",
  user: {},
  loadCount: 0,
  buddies: [],
};

const getters = {
  getProfile: (state) => state.user,
  isProfileLoaded: (state) => state.status === "success" && !!state.user.name,

  isCheckedIn: (state) => state.user && !!state.user.current_event,
  currentEvent: (state) =>
    state.user
      ? !state.user.current_event
        ? undefined
        : state.user.current_event
      : undefined,
  // canUseApp: state => (state.user && !!state.user.can_use_app ? true : false),

  getEventCount: (state) =>
    parseInt(state.user ? state.user.event_count || 0 : 0),
  
  getEventStatCounts: (state) => ({
    total: parseInt(state.user ? state.user.event_count || 0 : 0),
    future: parseInt(state.user ? state.user.event_future_count || 0 : 0),
    past: parseInt(state.user ? state.user.event_past_count || 0 : 0),
    today: parseInt(state.user ? state.user.event_today_count || 0 : 0),
  }),
    

  getMemberSince: (state) =>
    state.user && state.user.member_since
      ? new Date(state.user.member_since)
      : new Date(),

  getUsername: (state) => (state.user ? state.user.name || "" : null),
  getAvatar: (state) => (state.user ? state.user.avatar || "" : null),
  getEmail: (state) => (state.user ? state.user.email || "" : null),
  getLanguage: (state) =>
    state.user
      ? state.user.language || process.env.VUE_APP_I18N_FALLBACK_LOCALE
      : process.env.VUE_APP_I18N_FALLBACK_LOCALE,

  getBuddies: (state) =>
    state.user.buddies
      ? state.user.buddies.filter((b) => {
          return !!b.identifier;
        })
      : [],
  modalPreferences: (state) => state.user.modal_preferences || {},
  settings: (state) => state.user.settings || {},
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    api
      .get("api/user")
      .then((resp) => {
        commit(USER_SUCCESS, resp.data.user);
      })
      .catch(() => {
        commit(USER_ERROR);
        dispatch(AUTH_LOGOUT);
      });
  },
  [USER_FETCH_CACHED]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    try {
      // let userdata = ;
      commit(
        USER_SUCCESS,
        JSON.parse(localStorage.getItem(USER_CACHE_IDENTIFIER))
      );
    } catch (error) {
      commit(USER_ERROR);
      dispatch(AUTH_LOGOUT);
    }
  },

  [UPDATE_BUDDIES]: ({ commit }) => {
    api.get("api/user").then(({ data }) => {
      commit(UPDATE_BUDDIES, data.user.buddies);
    });
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = "loading";
  },

  [UPDATE_ANALYTICS]: (state, payload) => {
    state.user.enable_analytics = payload;
    UserRepository.updateAnalytics(payload);

    localStorage.setItem(USER_CACHE_IDENTIFIER, JSON.stringify(state.user));
    Vue.$cookies.set(USERID_COOKIE_IDENTIFIER, state.user.uuid);
  },

  [USER_SUCCESS]: (state, resp) => {
    state.loadCount++;
    state.status = "success";
    Vue.set(state, "user", resp);
    localStorage.setItem(USER_CACHE_IDENTIFIER, JSON.stringify(resp));
    Vue.$cookies.set(USERID_COOKIE_IDENTIFIER, resp.uuid);
  },

  [UPDATE_BUDDIES]: (state, buddies) => {
    Vue.set(state.user, "buddies", buddies);
  },

  [UPDATE_MODAL_PREFERENCES]: (state, payload) => {
    state.user.modal_preferences = payload;
    UserRepository.updateNotificationPreference(payload);
  },

  [USER_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.user = {};
    localStorage.removeItem(USER_CACHE_IDENTIFIER);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
